import CONSTANTS from "../constants";
import LoginService from "./LoginService";
import { customFetch as fetch } from "../helpers/FetchHelper";
import toast from "react-hot-toast";

export default {
  updateMenuPriority,
  saveMenu,
  delMenu,
  updateMenuOptions,
  getDummyItem,
  getModifiers,
  getAllModifiers,
  getAllDishTags,
  updateDishTags,
  deleteDishTags,
  saveDishTag,
  getDishTag,
  getAllergicItems,
  saveAllergicItem,
  deleteSelectedAllergicItems,
  deleteSelectedTags,
  updateAllergicItem,
  deleteAllergicItem,
  uploadMedia,
  uploadPDF,
  getAllPdfQrs,
  deletePdfQR,
  deleteMenuMedia,
  bulkUpdateDishTags,
  getAllMenuListPopup,
  getLocationMenu,
};

async function getLocationMenu(slug) {
  let { token } = LoginService.getToken();
  let url = `${CONSTANTS.API_URL}${CONSTANTS.URLS.MENU.GET_MENU(slug)}`;
  let response = await fetch(`${url}`, {
    method: "GET",
    headers: { ...CONSTANTS.REQUEST_HEADERS, "X-ACCESS-TOKEN": token },
  });
  response = await response.json();
  if (response) return response;
  throw new Error("Could not fetch Dummy Item");
}
async function getDummyItem(loc_id) {
  let { token } = LoginService.getToken();
  let url = `${CONSTANTS.API_URL}${CONSTANTS.URLS.MENU.GET_DUMMY_ITEM(loc_id)}`;
  let response = await fetch(`${url}`, {
    method: "GET",
    headers: { ...CONSTANTS.REQUEST_HEADERS, "X-ACCESS-TOKEN": token },
  });
  response = await response.json();
  if (response) return response;
  throw new Error("Could not fetch Dummy Item");
}

async function getModifiers(loc_id, menu_id) {
  let { token } = LoginService.getToken();
  let response = await fetch(
    `${CONSTANTS.API_URL}${CONSTANTS.URLS.MENU.GET_MODIFIERS(loc_id, menu_id)}`,
    {
      method: "GET",
      headers: { ...CONSTANTS.REQUEST_HEADERS, "X-ACCESS-TOKEN": token },
    }
  );
  response = await response.json();
  if (response) return response;
  throw new Error("Could not fetch Dummy Item");
}

async function getAllModifiers(loc_id) {
  let { token } = LoginService.getToken();
  let response = await fetch(
    `${CONSTANTS.API_URL}${CONSTANTS.URLS.MENU.GET_ALL_MODIFIERS(loc_id)}`,
    {
      method: "GET",
      headers: { ...CONSTANTS.REQUEST_HEADERS, "X-ACCESS-TOKEN": token },
    }
  );
  response = await response.json();
  if (response) return response;
  throw new Error("Could not fetch Dummy Item");
}
/* Dish tags */
async function getAllDishTags(loc_id) {
  let { token } = LoginService.getToken();
  let response = await fetch(
    `${CONSTANTS.API_URL}${CONSTANTS.URLS.MENU.GET_ALL_DISH_TAGS(loc_id)}`,
    {
      method: "GET",
      headers: { ...CONSTANTS.REQUEST_HEADERS, "X-ACCESS-TOKEN": token },
    }
  );
  response = await response.json();
  if (response) return response;
  throw new Error("Could not fetch tags");
}

async function getAllergicItems(loc_id) {
  let { token } = LoginService.getToken();
  let response = await fetch(
    `${CONSTANTS.API_URL}${CONSTANTS.URLS.MENU.GET_ALL_ALLERGIC_ITEMS(loc_id)}`,
    {
      method: "GET",
      headers: { ...CONSTANTS.REQUEST_HEADERS, "X-ACCESS-TOKEN": token },
    }
  );
  response = await response.json();
  if (response) return response;
  throw new Error("Could not fetch tags");
}

async function updateDishTags(loc_id, tag_id, params) {
  let { token } = LoginService.getToken();
  let response = await fetch(
    `${CONSTANTS.API_URL}${CONSTANTS.URLS.MENU.UPDATE_DISH_TAG(
      loc_id,
      tag_id
    )}`,
    {
      method: "POST",
      body: JSON.stringify(params),
      headers: { ...CONSTANTS.REQUEST_HEADERS, "X-ACCESS-TOKEN": token },
    }
  );
  response = await response.json();
  if (response) return response;
  throw new Error("Could not update tags");
}

async function bulkUpdateDishTags(loc_id, items) {
  let { token } = LoginService.getToken();
  let response = await fetch(
    `${CONSTANTS.API_URL}${CONSTANTS.URLS.MENU.UPDATEBULKTAGS(loc_id)}`,
    {
      method: "POST",
      body: JSON.stringify(items),
      headers: { ...CONSTANTS.REQUEST_HEADERS, "X-ACCESS-TOKEN": token },
    }
  );
  response = await response.json();
  if (response) return response;
  throw new Error("Could not update tags");
}

async function updateAllergicItem(loc_id, item_id, params) {
  let { token } = LoginService.getToken();
  let response = await fetch(
    `${CONSTANTS.API_URL}${CONSTANTS.URLS.MENU.UPDATE_ALLERGIC_ITEM(
      loc_id,
      item_id
    )}`,
    {
      method: "POST",
      body: JSON.stringify(params),
      headers: { ...CONSTANTS.REQUEST_HEADERS, "X-ACCESS-TOKEN": token },
    }
  );
  response = await response.json();
  if (response) return response;
  throw new Error("Could not update tags");
}

async function deleteAllergicItem(loc_id, item_id) {
  let { token } = LoginService.getToken();
  let response = await fetch(
    `${CONSTANTS.API_URL}${CONSTANTS.URLS.MENU.DELETE_ALLERGIC_ITEM(
      loc_id,
      item_id
    )}`,
    {
      method: "GET",
      headers: { ...CONSTANTS.REQUEST_HEADERS, "X-ACCESS-TOKEN": token },
    }
  );
  response = await response.json();
  if (response) return response;
  throw new Error("Could not delete tags");
}

async function deleteDishTags(loc_id, tag_id) {
  let { token } = LoginService.getToken();
  let response = await fetch(
    `${CONSTANTS.API_URL}${CONSTANTS.URLS.MENU.DELETE_DISH_TAG(
      loc_id,
      tag_id
    )}`,
    {
      method: "GET",
      headers: { ...CONSTANTS.REQUEST_HEADERS, "X-ACCESS-TOKEN": token },
    }
  );
  if (!response.ok) {
    toast.error("This tag is assigned to a Menu");
  }
  response = await response.json();
  if (response) return response;
  throw new Error("Could not delete tags");
}

async function saveDishTag(loc_id, item) {
  let { token } = LoginService.getToken();
  let response = await fetch(
    `${CONSTANTS.API_URL}${CONSTANTS.URLS.MENU.CREATE_DISH_TAG(loc_id)}`,
    {
      method: "POST",
      body: JSON.stringify(item),
      headers: { ...CONSTANTS.REQUEST_HEADERS, "X-ACCESS-TOKEN": token },
    }
  );
  response = await response.json();
  if (response) return response;
  throw new Error("Could not save dishtags");
}

async function saveAllergicItem(loc_id, item) {
  let { token } = LoginService.getToken();
  let response = await fetch(
    `${CONSTANTS.API_URL}${CONSTANTS.URLS.MENU.CREATE_ALLERGIC_ITEM(loc_id)}`,
    {
      method: "POST",
      body: JSON.stringify(item),
      headers: { ...CONSTANTS.REQUEST_HEADERS, "X-ACCESS-TOKEN": token },
    }
  );
  response = await response.json();
  if (response) return response;
  throw new Error("Could not save dishtags");
}

async function deleteSelectedAllergicItems(loc_id, items) {
  let { token } = LoginService.getToken();
  items = { item_ids: items };
  let response = await fetch(
    `${CONSTANTS.API_URL}${CONSTANTS.URLS.MENU.DELETE_SELECTED_ALLERGIC_ITEMS(
      loc_id
    )}`,
    {
      method: "POST",
      body: JSON.stringify(items),
      headers: { ...CONSTANTS.REQUEST_HEADERS, "X-ACCESS-TOKEN": token },
    }
  );
  response = await response.json();
  if (!response?.error) return response;
  throw response.message;
}

async function deleteSelectedTags(loc_id, tags) {
  let { token } = LoginService.getToken();
  tags = { tag_ids: tags };
  let response = await fetch(
    `${CONSTANTS.API_URL}${CONSTANTS.URLS.MENU.DELETE_SELECTED_DISH_TAGS(
      loc_id
    )}`,
    {
      method: "POST",
      body: JSON.stringify(tags),
      headers: { ...CONSTANTS.REQUEST_HEADERS, "X-ACCESS-TOKEN": token },
    }
  );
  response = await response.json();
  if (!response?.error) return response;
  throw response.message;
}

async function getDishTag(loc_id, tag_id) {
  let { token } = LoginService.getToken();
  let response = await fetch(
    `${CONSTANTS.API_URL}${CONSTANTS.URLS.MENU.GET_DISH_TAG(loc_id, tag_id)}`,
    {
      method: "GET",
      headers: { ...CONSTANTS.REQUEST_HEADERS, "X-ACCESS-TOKEN": token },
    }
  );
  response = await response.json();
  if (response) return response;
  throw new Error("Could not delete tags");
}

/* Dish tags */

async function updateMenuPriority(loc_id, menus) {
  let { token } = LoginService.getToken();
  let response = await fetch(
    `${CONSTANTS.API_URL}${CONSTANTS.URLS.MENU.BULK_UPDATE_PRIORITY(loc_id)}`,
    {
      method: "POST",
      body: JSON.stringify(menus),
      headers: { ...CONSTANTS.REQUEST_HEADERS, "X-ACCESS-TOKEN": token },
    }
  );
  response = await response.json();
  if (response) return response;
  throw new Error("Could not fetch Menu Priorities");
}

async function updateMenuOptions(loc_id, menu_id, optionIds) {
  let { token } = LoginService.getToken();
  let response = await fetch(
    `${CONSTANTS.API_URL}${CONSTANTS.URLS.MENU.MENU_UPDATE_OPTIONS(
      loc_id,
      menu_id
    )}`,
    {
      method: "POST",
      body: JSON.stringify(optionIds),
      headers: { ...CONSTANTS.REQUEST_HEADERS, "X-ACCESS-TOKEN": token },
    }
  );
  response = await response.json();
  if (response) return response;
  throw new Error("Could not fetch stats");
}

async function saveMenu(loc_id, item) {
  let { token } = LoginService.getToken();
  let response = await fetch(
    `${CONSTANTS.API_URL}${CONSTANTS.URLS.MENU.DEFAULT(loc_id)}`,
    {
      method: "POST",
      body: item,
      headers: {
        Accept: CONSTANTS.REQUEST_HEADERS.Accept,
        "X-ACCESS-TOKEN": token,
      },
    }
  );
  response = await response.json();
  if (response) return response;

  throw new Error("Could not save category");
}

async function delMenu(loc_id, menu) {
  let { token } = LoginService.getToken();
  let response = await fetch(
    `${CONSTANTS.API_URL}${CONSTANTS.URLS.MENU.DEFAULT(loc_id)}/${menu}`,
    {
      method: "DELETE",
      headers: { ...CONSTANTS.REQUEST_HEADERS, "X-ACCESS-TOKEN": token },
    }
  );
  response = await response.json();
  if (response) return response;

  throw new Error("Could not delete category");
}

async function uploadMedia(locationData, menu_id, location_id) {
  let { token } = LoginService.getToken();
  let response = await fetch(
    `${CONSTANTS.API_URL}${CONSTANTS.URLS.MENU.UPLOADMEDIA(
      location_id,
      menu_id
    )}`,
    {
      body: locationData,
      method: "POST",
      headers: {
        Accept: CONSTANTS.REQUEST_HEADERS.Accept,
        "X-ACCESS-TOKEN": token,
      },
    }
  );
  response = await response.json();
  return response;
}

async function uploadPDF(pdf, location_id) {
  let { token } = LoginService.getToken();
  let response = await fetch(
    `${CONSTANTS.API_URL}${CONSTANTS.URLS.MENU.UPLOAD_PDF(location_id)}`,
    {
      body: pdf,
      method: "POST",
      headers: {
        Accept: CONSTANTS.REQUEST_HEADERS.Accept,
        "X-ACCESS-TOKEN": token,
      },
    }
  );
  response = await response.json();
  return response;
}

async function getAllPdfQrs(location_id) {
  let { token } = LoginService.getToken();
  let response = await fetch(
    `${CONSTANTS.API_URL}${CONSTANTS.URLS.MENU.GET_ALL_PDF_QRS(location_id)}`,
    {
      method: "GET",
      headers: {
        Accept: CONSTANTS.REQUEST_HEADERS.Accept,
        "X-ACCESS-TOKEN": token,
      },
    }
  );
  response = await response.json();
  return response;
}
async function deletePdfQR(location_id, id) {
  let { token } = LoginService.getToken();
  let response = await fetch(
    `${CONSTANTS.API_URL}${CONSTANTS.URLS.MENU.DELETE_PDF_QR(location_id, id)}`,
    {
      method: "DELETE",
      headers: {
        Accept: CONSTANTS.REQUEST_HEADERS.Accept,
        "X-ACCESS-TOKEN": token,
      },
    }
  );
  response = await response.json();
  return response;
}

async function deleteMenuMedia(location_id, menu_media_id) {
  let { token } = LoginService.getToken();
  let response = await fetch(
    `${CONSTANTS.API_URL}${CONSTANTS.URLS.MENU.DELETEMEDIA(
      location_id,
      menu_media_id
    )}`,
    {
      method: "GET",
      headers: {
        Accept: CONSTANTS.REQUEST_HEADERS.Accept,
        "X-ACCESS-TOKEN": token,
      },
    }
  );
  response = await response.json();
  return response;
}

async function getAllMenuListPopup(loc_id) {
  let { token } = LoginService.getToken();
  let url = `${CONSTANTS.API_URL}${CONSTANTS.URLS.MENU.GET_ALL_POPUP_MENU(
    loc_id
  )}`;
  let response = await fetch(`${url}`, {
    method: "GET",
    headers: { ...CONSTANTS.REQUEST_HEADERS, "X-ACCESS-TOKEN": token },
  });
  response = await response.json();
  if (response) return response;
  throw new Error("Could not fetch Popup Menu Item");
}
