// import React from "react";
// import Select from "react-select";
// import { Button, Row, Col, Card, CardBody } from "reactstrap";
// import "../../assets/scss/plugins/forms/react-select/_react-select.scss";
// import "../../assets/scss/pages/app-ecommerce-shop.scss";
// import { X } from "react-feather";
// import FREETABLE from "../../assets/img/pages/table_empty.png";
// import BOOKEDTABLE from "../../assets/img/pages/table_booked.png";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {
//   faFire,
//   faGlassCheers,
//   faBirthdayCake,
//   faClock,
//   faCoins,
//   faTimes,
//   faStopwatch,
//   faMoneyBill,
// } from "@fortawesome/free-solid-svg-icons";
// import { withTranslation } from "react-i18next";
// import i18n from "i18next";
// import TimeHelper from "../../helpers/TimeHelper";

// class ListSessionTablesStatus extends React.Component {
//   state = {
//     inCart: [],
//     inWishlist: [],
//     view: "grid-view",
//   };

//   componentDidMount() {
//     const savedLanguage = localStorage.getItem("lang");
//     if (savedLanguage) {
//       i18n.changeLanguage(savedLanguage);
//     }
//   }
//   getOrderFromDisplayName = (orderFrom) => {
//     switch (orderFrom) {
//       case "QR Scan":
//         return "QuickServe";
//       case "Eater App":
//         return "ServTable";
//       case "Waiter App":
//         return "ServWait";
//       default:
//         return orderFrom;
//     }
//   };

//   renderTablesData = (tables) => {
//     const { t } = this.props;
//     let renderTables = tables.map((table, index) => {
//       const sessionTime = new Date(
//         table?.LocationSessions[0]?.session_date +
//           " " +
//           table?.LocationSessions[0]?.start_time
//       );
//       const sessionTimeCorrect = TimeHelper.toFormattedTime(sessionTime);
//       console.log("TABLESDATA: " + table);
//       if (table.table_status === false) {
//         return null;
//       }

//       return (
//         <div className="col-lg-3 col-md-4 col-sm-6" key={index}>
//           {table.table_status && (
//             <Card className="ecommerce-card table-session">
//               <div className="card-content">
//                 <div className="row">
//                   <div className="col-12">
//                     <span className="float-left font-weight-bold">
//                       <FontAwesomeIcon
//                         className=" text-white"
//                         icon={faClock}
//                         style={{ fontSize: "20px" }}
//                       />

//                       {table.is_table_available
//                         ? " 00"
//                         : ` ${this.getSessionTime(sessionTime)}`}
//                     </span>
//                     <span className="float-right font-weight-bold">
//                       <FontAwesomeIcon
//                         className="mr-1"
//                         style={{ fontSize: "20px", color: "gold" }}
//                         icon={faCoins}
//                       />
//                       {table.is_table_available
//                         ? 0
//                         : table?.LocationSessions[0]?.Orders.length > 0
//                         ? this.props.location.currency +
//                           " " +
//                           this.formatAmount(
//                             table?.LocationSessions[0]?.Orders[0]
//                               .session_order_amount
//                           )
//                         : 0}
//                     </span>
//                   </div>
//                 </div>

//                 <div className="item-img text-center">
//                   {table.is_table_available ? (
//                     <img
//                       style={{ filter: "brightness(2)" }}
//                       className="img-fluid"
//                       src={FREETABLE}
//                       alt={table.table_name}
//                     />
//                   ) : (
//                     <img
//                       style={{ filter: "brightness(2)" }}
//                       className="img-fluid"
//                       src={BOOKEDTABLE}
//                       alt={table.table_name}
//                     />
//                   )}

//                   <h4 className="col-12">
//                     {table.table_name ? (
//                       <div className="font-weight-bold text-white">
//                         {table.table_name}
//                       </div>
//                     ) : (
//                       <div
//                         className="font-weight-bold"
//                         style={{ height: "1.5rem" }}
//                       ></div>
//                     )}

//                     <h4 className="col-12 text-white font-weight-bold mt-1">
//                       {table?.LocationSessions[0]?.session_status === "started"
//                         ? this.getOrderFromDisplayName(
//                             table?.LocationSessions[0]?.order_from
//                           )
//                         : null}
//                     </h4>

//                     {table.is_table_available ? (
//                       <span>
//                         {" "}
//                         <button
//                           type="submit"
//                           className="cart btn btn-success mt-1 col-6 "
//                           style={{ cursor: "not-allowed" }}
//                         >
//                           {t("free")}{" "}
//                         </button>
//                       </span>
//                     ) : (
//                       <button
//                         onClick={() => {
//                           this.props.closeLocationSession(
//                             table?.LocationSessions[0]?.session_id
//                           );
//                         }}
//                         type="submit"
//                         className="cart btn btn-warning mt-1"
//                       >
//                         {t("close-session")}{" "}
//                       </button>
//                     )}
//                   </h4>
//                 </div>

//                 <CardBody className="text-center">
//                   <div className="item-wrapper">
//                     <div className="product-price">
//                       <h4 className="item-price">
//                         {table.is_table_available ? (
//                           <span className=" font-weight-bold success">
//                             <br></br>
//                           </span>
//                         ) : (
//                           <>
//                             <FontAwesomeIcon
//                               className=" text-white mr-1"
//                               icon={faStopwatch}
//                               style={{ fontSize: "20px" }}
//                             />
//                             <span className=" font-weight-bold warning">
//                               <span className=" font-weight-bold warning"></span>
//                               {sessionTimeCorrect}
//                             </span>
//                           </>
//                         )}
//                       </h4>
//                     </div>
//                   </div>
//                 </CardBody>
//               </div>
//             </Card>
//           )}
//         </div>
//       );
//     });
//     // Filter out null values (tables with table_status false)
//     renderTables = renderTables.filter((table) => table !== null);
//     return renderTables;
//   };

//   getSessionTime = (time_diff) => {
//     let cDate = new Date();
//     console.log("CDATE", cDate);
//     let sDate = new Date(time_diff);
//     console.log("sdate", sDate);
//     const diffTime = Math.abs(cDate - sDate);
//     let diffMinutes = Math.ceil(diffTime / (1000 * 60));

//     console.log("DIFFERENCE TIME", diffMinutes);

//     let result;
//     if (diffMinutes > 60) {
//       const diffHours = Math.floor(diffMinutes / 60);
//       result = `${diffHours} Hrs`;
//     } else {
//       result = `${diffMinutes} Minutes`;
//     }

//     return result;
//   };

//   formatAmount = (amount) => {
//     if (amount >= 1000) {
//       if (amount >= 1000000 && amount < 1000000000) {
//         return (amount / 1000000).toFixed(1) + "M";
//       } else if (amount >= 1000000000) {
//         return (amount / 1000000000).toFixed(1) + "B";
//       } else {
//         return (amount / 1000).toFixed(1) + "K";
//       }
//     } else {
//       return amount.toFixed(2);
//     }
//   };

//   render() {
//     const { tables } = this.props;
//     const { t } = this.props;

//     console.log(this.props);

//     return (
//       <div className="col-12">
//         <Row>
//           <Col sm="12">
//             <div id="ecommerce-products">
//               <Row>{this.renderTablesData(tables?.data)}</Row>
//             </div>
//           </Col>
//         </Row>
//       </div>
//     );
//   }
// }

// export default withTranslation("global")(ListSessionTablesStatus);

// import React from "react";
// import { Button, Row, Col } from "reactstrap";
// import DataTable from "react-data-table-component";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {
//   faClock,
//   faCoins,
//   faStopwatch,
// } from "@fortawesome/free-solid-svg-icons";
// import { withTranslation } from "react-i18next";
// import i18n from "i18next";
// import TimeHelper from "../../helpers/TimeHelper";
// import FREETABLE from "../../assets/img/pages/table_empty.png";
// import BOOKEDTABLE from "../../assets/img/pages/table_booked.png";
// import QRCode from "qrcode.react";

// class ListSessionTablesStatus extends React.Component {
//   state = {
//     inCart: [],
//     inWishlist: [],
//     view: "grid-view",
//     qrModalOpen: false,
//     qrImageUrl: "",
//     qrImageName: "",
//     redirectURL: "",
//   };

//   componentDidMount() {
//     const savedLanguage = localStorage.getItem("lang");
//     if (savedLanguage) {
//       i18n.changeLanguage(savedLanguage);
//     }
//   }

//   getOrderFromDisplayName = (orderFrom) => {
//     switch (orderFrom) {
//       case "QR Scan":
//         return "QuickServe";
//       case "Eater App":
//         return "ServTable";
//       case "Waiter App":
//         return "ServWait";
//       default:
//         return orderFrom;
//     }
//   };

//   getSessionTime = (time_diff) => {
//     let cDate = new Date();
//     let sDate = new Date(time_diff);
//     const diffTime = Math.abs(cDate - sDate);
//     let diffMinutes = Math.ceil(diffTime / (1000 * 60));

//     let result;
//     if (diffMinutes > 60) {
//       const diffHours = Math.floor(diffMinutes / 60);
//       result = `${diffHours} Hrs`;
//     } else {
//       result = `${diffMinutes} Minutes`;
//     }

//     return result;
//   };

//   formatAmount = (amount) => {
//     if (amount >= 1000) {
//       if (amount >= 1000000 && amount < 1000000000) {
//         return (amount / 1000000).toFixed(1) + "M";
//       } else if (amount >= 1000000000) {
//         return (amount / 1000000000).toFixed(1) + "B";
//       } else {
//         return (amount / 1000).toFixed(1) + "K";
//       }
//     } else {
//       return amount.toFixed(2);
//     }
//   };

//   openQrModal = (qrCodeUrl, qrImageName, url) => {
//     this.setState({
//       qrModalOpen: true,
//       qrImageUrl: qrCodeUrl,
//       qrImageName: qrImageName,
//       redirectURL: url,
//     });
//   };

//   closeQrModal = () => {
//     this.setState({
//       qrModalOpen: false,
//       qrImageUrl: "",
//       qrImageName: "",
//       redirectURL: "",
//     });
//   };

//   render() {
//     const { tables } = this.props;
//     const { t } = this.props;

//     const tableData = tables?.data.map((table, index) => {
//       const sessionTime = new Date(
//         table?.LocationSessions[0]?.session_date +
//           " " +
//           table?.LocationSessions[0]?.start_time
//       );
//       const sessionTimeCorrect = TimeHelper.toFormattedTime(sessionTime);
//       const tableAvailable = table.is_table_available;
//       const orderAmount =
//         table?.LocationSessions[0]?.Orders.length > 0
//           ? this.props.location.currency +
//             this.formatAmount(
//               table?.LocationSessions[0]?.Orders[0].session_order_amount
//             )
//           : 0;

//       return {
//         id: index,
//         name: table.table_name,
//         status: tableAvailable ? "Free" : "Occupied",
//         time: tableAvailable ? "00" : this.getSessionTime(sessionTime),
//         amount: tableAvailable ? 0 : orderAmount,
//         sessionStatus:
//           table?.LocationSessions[0]?.session_status === "started"
//             ? this.getOrderFromDisplayName(
//                 table?.LocationSessions[0]?.order_from
//               )
//             : null,
//         sessionTime: tableAvailable ? null : sessionTimeCorrect,
//         image: tableAvailable ? FREETABLE : BOOKEDTABLE,
//         sessionId: table?.LocationSessions[0]?.session_id,
//         qrCodeData: table.qrcodedata,
//       };
//     });

//     const columns = [
//       {
//         name: t("Table Name"),
//         selector: (row) => row.name,
//         sortable: true,
//       },
//       {
//         name: t("Status"),
//         selector: (row) => row.status,
//         sortable: true,
//       },
//       {
//         name: t("Time"),
//         selector: (row) => row.time,
//         sortable: true,
//         cell: (row) => (
//           <span>
//             <FontAwesomeIcon icon={faClock} style={{ fontSize: "20px" }} />
//             {row.time}
//           </span>
//         ),
//       },
//       {
//         name: t("Amount"),
//         selector: (row) => row.amount,
//         sortable: true,
//         cell: (row) => (
//           <span>
//             <FontAwesomeIcon
//               icon={faCoins}
//               style={{ fontSize: "20px", color: "gold" }}
//             />
//             {row.amount}
//           </span>
//         ),
//       },
//       {
//         name: t("Session Status"),
//         selector: (row) => row.sessionStatus,
//         sortable: true,
//       },
//       {
//         name: t("Session Time"),
//         selector: (row) => row.sessionTime,
//         sortable: true,
//         cell: (row) =>
//           row.sessionTime && (
//             <span>
//               <FontAwesomeIcon
//                 icon={faStopwatch}
//                 style={{ fontSize: "20px" }}
//               />
//               {row.sessionTime}
//             </span>
//           ),
//       },
//       {
//         name: t("Image"),
//         selector: (row) => row.image,
//         cell: (row) => (
//           <img
//             width={70}
//             className="img-fluid"
//             src={row.image}
//             alt={row.name}
//           />
//         ),
//       },

//       {
//         name: t("QR Code"),
//         selector: (row) => row.qrCodeData,

//         sortable: true,
//         cell: (row) =>
//           row.qrCodeData ? (
//             <Button
//               outline
//               className="btn-icon"
//               color="primary"
//               onClick={() =>
//                 this.openQrModal(row.qrCodeData, row.name, row.qrCodeData)
//               }
//             >
//               <QRCode value={row.qrCodeData} size={35} />
//             </Button>
//           ) : null,
//       },
//       {
//         name: t("Action"),
//         cell: (row) =>
//           row.status === "Occupied" && (
//             <Button
//               onClick={() => {
//                 this.props.closeLocationSession(row.sessionId);
//               }}
//               type="submit"
//               className="cart btn btn-warning mt-1"
//             >
//               {t("close-session")}
//             </Button>
//           ),
//       },
//     ];

//     return (
//       <>
//         <div className="col-12">
//           <Row>
//             <Col sm="12">
//               <DataTable
//                 noHeader
//                 pagination
//                 paginationRowsPerPageOptions={[5, 10, 20]}
//                 paginationPerPage={4}
//                 columns={columns}
//                 data={tableData}
//               />
//             </Col>
//           </Row>
//         </div>
//       </>
//     );
//   }
// }

// export default withTranslation("global")(ListSessionTablesStatus);

// import React from "react";
// import { Button, Row, Col, ModalBody, Modal } from "reactstrap";
// import DataTable from "react-data-table-component";
// import DataTableExtensions from "react-data-table-component-extensions";
// import { withTranslation } from "react-i18next";
// import i18n from "i18next";
// import TimeHelper from "../../helpers/TimeHelper";
// import FREETABLE from "../../assets/img/pages/table_empty.png";
// import BOOKEDTABLE from "../../assets/img/pages/table_booked.png";
// import QRCode from "qrcode.react";
// import { Link, X } from "react-feather";
// import { saveAs } from "file-saver";
// import CONSTANTS from "../../constants";

// class ListSessionTablesStatus extends React.Component {
//   state = {
//     inCart: [],
//     inWishlist: [],
//     view: "grid-view",
//     qrModalOpen: false,
//     qrImageUrl: "",
//     qrImageName: "",
//     redirectURL: "",
//   };

//   componentDidMount() {
//     const savedLanguage = localStorage.getItem("lang");
//     if (savedLanguage) {
//       i18n.changeLanguage(savedLanguage);
//     }
//   }

//   getOrderFromDisplayName = (orderFrom) => {
//     switch (orderFrom) {
//       case "QR Scan":
//         return "QuickServe";
//       case "Eater App":
//         return "ServTable";
//       case "Waiter App":
//         return "ServWait";
//       default:
//         return orderFrom;
//     }
//   };

//   getSessionTime = (time_diff) => {
//     let cDate = new Date();
//     let sDate = new Date(time_diff);
//     const diffTime = Math.abs(cDate - sDate);
//     let diffMinutes = Math.ceil(diffTime / (1000 * 60));

//     let result;
//     if (diffMinutes > 60) {
//       const diffHours = Math.floor(diffMinutes / 60);
//       result = `${diffHours} Hrs`;
//     } else {
//       result = `${diffMinutes} Minutes`;
//     }

//     return result;
//   };

//   formatAmount = (amount) => {
//     if (amount >= 1000) {
//       if (amount >= 1000000 && amount < 1000000000) {
//         return (amount / 1000000).toFixed(1) + "M";
//       } else if (amount >= 1000000000) {
//         return (amount / 1000000000).toFixed(1) + "B";
//       } else {
//         return (amount / 1000).toFixed(1) + "K";
//       }
//     } else {
//       return amount.toFixed(2);
//     }
//   };

//   openQrModal = (qrCodeUrl, qrImageName, url) => {
//     this.setState({
//       qrModalOpen: true,
//       qrImageUrl: qrCodeUrl,
//       qrImageName: qrImageName,
//       redirectURL: url,
//     });
//   };

//   closeQrModal = () => {
//     this.setState({
//       qrModalOpen: false,
//       qrImageUrl: "",
//       qrImageName: "",
//       redirectURL: "",
//     });
//   };

//   redirectToURL = (url) => {
//     this.closeQrModal();
//     window.open(url, "_blank");
//   };

//   goToOrderSession = (sessionId) => {
//     console.log("SESSIONID", sessionId);
//     window.open(`${CONSTANTS.BASE_URL}sessions/${sessionId}`, "_self");
//   };
//   render() {
//     const { tables, t } = this.props;
//     console.log("TABLES & ROOMS", tables);

//     const customStyles = {
//       headCells: {
//         style: {
//           fontWeight: "bold",
//         },
//       },
//     };

//     const buttonStyle = {
//       flex: 1,
//       margin: "5px",
//       display: "flex",
//       alignItems: "center",
//     };

//     const tableData = tables?.data.map((table, index) => {
//       const sessionTime = new Date(
//         table?.LocationSessions[0]?.session_date +
//           " " +
//           table?.LocationSessions[0]?.start_time
//       );
//       const sessionTimeCorrect = TimeHelper.toFormattedTime(sessionTime);
//       const tableAvailable = table?.is_table_available;
//       const orderAmount =
//         table?.LocationSessions[0]?.Orders.length > 0
//           ? this.props.location?.currency +
//             " " +
//             this.formatAmount(
//               table?.LocationSessions[0]?.Orders[0].session_order_amount
//             )
//           : 0;

//       return {
//         id: index,
//         name: table.table_name,
//         status: tableAvailable ? "Free" : "Occupied",
//         time: tableAvailable ? "00" : this.getSessionTime(sessionTime),
//         amount: tableAvailable ? 0 : orderAmount,
//         floorName: table?.Floor?.floor_name,
//         sessionStatus:
//           table?.LocationSessions[0]?.session_status === "started"
//             ? this.getOrderFromDisplayName(
//                 table?.LocationSessions[0]?.order_from
//               )
//             : null,
//         sessionTime: tableAvailable ? null : sessionTimeCorrect,
//         image: tableAvailable ? FREETABLE : BOOKEDTABLE,
//         sessionId: table?.LocationSessions[0]?.session_id,
//         qrCodeData: table.qrcodedata,
//         sessions: table?.LocationSessions[0]?.session_id,
//         sessionsStatus: table?.LocationSessions[0]?.session_status,
//       };
//     });

//     const columns = [
//       {
//         name: t("Table Name"),
//         selector: (row) => row?.name,
//         sortable: true,
//       },
//       {
//         name: t("Floor Name"),
//         selector: (row) => row?.floorName,
//         sortable: true,
//       },
//       {
//         name: t("Amount"),
//         selector: (row) => row?.amount,
//         sortable: true,
//         cell: (row) => <span>{row?.amount}</span>,
//       },
//       {
//         name: "Time Elapsed",
//         selector: (row) => row?.time,
//         sortable: true,
//         cell: (row) => <span>{row?.time}</span>,
//       },
//       {
//         name: t("Session Time"),
//         selector: (row) => row?.sessionTime,
//         sortable: true,
//         cell: (row) => row.sessionTime && <span>{row?.sessionTime}</span>,
//       },
//       {
//         name: "Order From",
//         selector: (row) => row?.sessionStatus,
//         sortable: true,
//       },
//       {
//         width: "200px",
//         name: "Session Id",
//         selector: (row) => row?.sessions,
//         sortable: true,

//         cell: (row) => {
//           if (row?.sessionsStatus === "started") {
//             return (
//               <button
//                 onClick={() => this.goToOrderSession(row?.sessions)}
//                 className="btn  btn-link"
//               >
//                 <span> {row?.sessions}</span>
//               </button>
//             );
//           } else {
//             return null;
//           }
//         },
//       },

//       {
//         name: t("QR Code"),
//         selector: (row) => row?.qrCodeData,
//         sortable: true,
//         cell: (row) =>
//           row?.qrCodeData ? (
//             <Button
//               outline
//               className="btn-icon"
//               color="primary"
//               onClick={() =>
//                 this.openQrModal(row?.qrCodeData, row?.name, row?.qrCodeData)
//               }
//             >
//               <QRCode value={row?.qrCodeData} size={35} />
//             </Button>
//           ) : null,
//       },
//       {
//         name: t("Action"),
//         width: "200px",
//         cell: (row) =>
//           row?.status === "Occupied" ? (
//             <Button
//               onClick={() => this.props.closeLocationSession(row?.sessionId)}
//               type="submit"
//               className="cart btn  btn-warning"
//             >
//               {t("close-session")}
//             </Button>
//           ) : (
//             <Button type="button" className="cart btn  btn-success" disabled>
//               {t("Free")}
//             </Button>
//           ),
//       },
//     ];

//     const tableDataFinal = {
//       columns: columns,
//       data: tableData,
//       print: false,
//       export: false,
//     };
//     return (
//       <>
//         <div className="col-12">
//           <Row>
//             <Col sm="12" lg="12">
//               <DataTableExtensions {...tableDataFinal}>
//                 <DataTable
//                   noHeader
//                   pagination
//                   paginationRowsPerPageOptions={[25, 50, 100]}
//                   paginationPerPage={50}
//                   columns={columns}
//                   data={tableData}
//                   customStyles={customStyles}
//                 />
//               </DataTableExtensions>
//             </Col>
//           </Row>
//         </div>
//         <Modal
//           style={{
//             display: "flex",
//             alignItems: "center",
//             justifyContent: "center",
//             maxWidth: "500px",
//           }}
//           isOpen={this.state.qrModalOpen}
//           toggle={this.closeQrModal}
//         >
//           <ModalBody>
//             <div className="display-flex full-width flex-space-between">
//               <Button.Ripple
//                 style={{ width: 20, height: 20 }}
//                 onClick={this.closeQrModal}
//                 className="m-0 p-0"
//                 color="danger"
//               >
//                 <X size={14} />
//               </Button.Ripple>
//             </div>
//             <div style={{ textAlign: "center", padding: "50px" }}>
//               <div
//                 style={{
//                   display: "flex",
//                   flexDirection: "column",
//                   alignItems: "center",
//                 }}
//               >
//                 <Button
//                   style={buttonStyle}
//                   className="btn-icon"
//                   color="primary"
//                   onClick={() => this.redirectToURL(this.state.redirectURL)}
//                 >
//                   <Link size={16} style={{ marginRight: "5px" }} />{" "}
//                   {t("qr-url")}
//                 </Button>
//                 <h4 style={{ marginBottom: "-3px" }}>
//                   {this.state.qrImageName}
//                 </h4>
//                 <div
//                   style={{
//                     display: "flex",
//                     alignItems: "center",
//                     justifyContent: "center",
//                     height: "100%",
//                   }}
//                 >
//                   <QRCode value={this.state.qrImageUrl} size={100} />
//                 </div>
//                 <Button
//                   style={buttonStyle}
//                   className="btn-icon"
//                   color="primary"
//                   onClick={() =>
//                     saveAs(
//                       this.state.qrImageUrl,
//                       `${this.state.qrImageName}.jpeg`
//                     )
//                   }
//                 >
//                   {t("download")}
//                 </Button>
//               </div>
//             </div>
//           </ModalBody>
//         </Modal>
//       </>
//     );
//   }
// }

// export default withTranslation("global")(ListSessionTablesStatus);
import React, {createRef} from "react";
import {
  Button,
  Row,
  Col,
  ModalBody,
  Modal,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import { withTranslation } from "react-i18next";
import TimeHelper from "../../helpers/TimeHelper";
import FREETABLE from "../../assets/img/pages/table_empty.png";
import BOOKEDTABLE from "../../assets/img/pages/table_booked.png";
import QRCode from "qrcode.react";
import { Link, X } from "react-feather";
import { saveAs } from "file-saver";
import CONSTANTS from "../../constants";
import i18n from "i18next";
import {toJpeg} from "html-to-image";

class ListSessionTablesStatus extends React.Component {
  qrRef = createRef();
  state = {
    inCart: [],
    inWishlist: [],
    view: "grid-view",
    qrModalOpen: false,
    qrImageUrl: "",
    qrImageName: "",
    redirectURL: "",
    activeTab: "1",
  };

  componentDidMount() {
    const savedLanguage = localStorage.getItem("lang");
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }
  }
  toggleTab = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  };

  getOrderFromDisplayName = (orderFrom) => {
    switch (orderFrom) {
      case "QR Scan":
        return "QuickServe";
      case "Eater App":
        return "ServTable";
      case "Waiter App":
        return "ServWait";
      default:
        return orderFrom;
    }
  };

  getSessionTime = (time_diff) => {
    let cDate = new Date();
    let sDate = new Date(time_diff);
    const diffTime = Math.abs(cDate - sDate);
    let diffMinutes = Math.ceil(diffTime / (1000 * 60));

    let result;
    if (diffMinutes > 60) {
      const diffHours = Math.floor(diffMinutes / 60);
      result = `${diffHours} Hrs`;
    } else {
      result = `${diffMinutes} Minutes`;
    }

    return result;
  };

  formatAmount = (amount) => {
    if (amount >= 1000) {
      if (amount >= 1000000 && amount < 1000000000) {
        return (amount / 1000000).toFixed(1) + "M";
      } else if (amount >= 1000000000) {
        return (amount / 1000000000).toFixed(1) + "B";
      } else {
        return (amount / 1000).toFixed(1) + "K";
      }
    } else {
      return amount.toFixed(2);
    }
  };

  openQrModal = (qrCodeUrl, qrImageName, url, image_name) => {
    this.setState({
      qrModalOpen: true,
      qrImageUrl: qrCodeUrl,
      qrImageName: qrImageName,
      redirectURL: url,
      imageDownload_URL: image_name,
    });
  };

  closeQrModal = () => {
    this.setState({
      qrModalOpen: false,
      qrImageUrl: "",
      qrImageName: "",
      redirectURL: "",
      imageDownload_URL: "",
    });
  };

  redirectToURL = (url) => {
    this.closeQrModal();
    window.open(url, "_blank");
  };

  goToOrderSession = (sessionId) => {
    console.log("SESSIONID", sessionId);
    window.open(`${CONSTANTS.BASE_URL}sessions/${sessionId}`, "_self");
  };

  handleDownloadImage = async () => {
    let url = CONSTANTS.RESTAURANT_URL + this.state.imageDownload_URL;

    if (this.qrRef.current) {
      toJpeg(this.qrRef.current)
          .then((dataUrl) => {
            saveAs(dataUrl, `${this.state.qrImageName}.jpeg`);
          })
          .catch((error) => {
            console.error('Error saving QR code image', error);
          });
    }
  }

  render() {
    const { tables, rooms, t } = this.props;
    console.log("TABLES & ROOMS", tables, rooms);

    const customStyles = {
      headCells: {
        style: {
          fontWeight: "bold",
        },
      },
    };

    const buttonStyle = {
      flex: 1,
      margin: "5px",
      display: "flex",
      alignItems: "center",
    };

    const tableData = tables?.data.map((table, index) => {
      const sessionTime = new Date(
          table?.LocationSessions[0]?.session_date +
          " " +
          table?.LocationSessions[0]?.start_time
      );
      const sessionTimeCorrect = TimeHelper.toFormattedTime(sessionTime);
      const tableAvailable = table?.is_table_available;
      const orderAmount =
          table?.LocationSessions[0]?.Orders.length > 0
              ? this.props.location?.currency +
              " " +
              this.formatAmount(
                  table?.LocationSessions[0]?.Orders[0].session_order_amount
              )
              : 0;

      return {
        id: index,
        name: table.table_name,
        status: tableAvailable ? "Free" : "Occupied",
        time: tableAvailable ? "00" : this.getSessionTime(sessionTime),
        amount: tableAvailable ? 0 : orderAmount,
        floorName: table?.Floor?.floor_name,
        sessionStatus:
            table?.LocationSessions[0]?.session_status === "started"
                ? this.getOrderFromDisplayName(
                    table?.LocationSessions[0]?.order_from
                )
                : null,
        sessionTime: tableAvailable ? null : sessionTimeCorrect,
        image: tableAvailable ? FREETABLE : BOOKEDTABLE,
        sessionId: table?.LocationSessions[0]?.session_id,
        qrCodeData: table.qrcodedata,
        sessions: table?.LocationSessions[0]?.session_id,
        sessionsStatus: table?.LocationSessions[0]?.session_status,
        image_name: table.image_name ? table.image_name : "",
      };
    });

    const roomData = tables.rooms?.map((room, index) => {
      const sessionTime = new Date(
          room?.LocationSessions[0]?.session_date +
          " " +
          room?.LocationSessions[0]?.start_time
      );
      const sessionTimeCorrect = TimeHelper.toFormattedTime(sessionTime);
      const roomAvailable = room?.room_available_restaurant;
      const orderAmount =
          room?.LocationSessions[0]?.Orders.length > 0
              ? this.props.location?.currency +
              " " +
              this.formatAmount(
                  room?.LocationSessions[0]?.Orders[0].session_order_amount
              )
              : 0;

      return {
        id: index,
        name: room.room_name,
        status: roomAvailable ? "Free" : "Occupied",
        time: roomAvailable ? "00" : this.getSessionTime(sessionTime),
        amount: roomAvailable ? 0 : orderAmount,
        hotelName: room?.hotel_name,
        sessionStatus:
            room?.LocationSessions[0]?.session_status === "started"
                ? this.getOrderFromDisplayName(
                    room?.LocationSessions[0]?.order_from
                )
                : null,
        sessionTime: roomAvailable ? null : sessionTimeCorrect,
        image: roomAvailable ? FREETABLE : BOOKEDTABLE,
        sessionId: room?.LocationSessions[0]?.session_id,
        qrCodeData: room.qrcodedata,
        sessions: room?.LocationSessions[0]?.session_id,
        sessionsStatus: room?.LocationSessions[0]?.session_status,
      };
    });

    const tableColumns = [
      {
        name: t("Table Name"),
        selector: (row) => row?.name,
        sortable: true,
      },
      {
        name: t("Floor Name"),
        selector: (row) => row?.floorName,
        sortable: true,
      },
      {
        name: t("Amount"),
        selector: (row) => row?.amount,
        sortable: true,
        cell: (row) => <span>{row?.amount}</span>,
      },
      {
        name: "Time Elapsed",
        selector: (row) => row?.time,
        sortable: true,
        cell: (row) => <span>{row?.time}</span>,
      },
      {
        name: t("Session Time"),
        selector: (row) => row?.sessionTime,
        sortable: true,
        cell: (row) => row.sessionTime && <span>{row?.sessionTime}</span>,
      },
      {
        name: "Order From",
        selector: (row) => row?.sessionStatus,
        sortable: true,
      },
      {
        width: "200px",
        name: "Session Id",
        selector: (row) => row?.sessions,
        sortable: true,
        cell: (row) => {
          if (row?.sessionsStatus === "started") {
            return (
                <button
                    onClick={() => this.goToOrderSession(row?.sessions)}
                    className="btn  btn-link"
                >
                  <span> {row?.sessions}</span>
                </button>
            );
          } else {
            return null;
          }
        },
      },
      {
        name: t("QR Code"),
        selector: (row) => row?.qrCodeData,
        sortable: true,
        cell: (row) =>
            row?.qrCodeData ? (
                <Button
                    outline
                    className="btn-icon"
                    color="primary"
                    onClick={() =>
                        this.openQrModal(row?.qrCodeData, row?.name, row?.qrCodeData)
                    }
                >
                  <QRCode value={row?.qrCodeData} size={35}  renderAs={"svg"}/>
                </Button>
            ) : null,
      },
      {
        name: t("Action"),
        width: "200px",
        cell: (row) =>
            row?.status === "Occupied" ? (
                <Button
                    onClick={() => this.props.closeLocationSession(row?.sessionId)}
                    type="submit"
                    className="cart btn  btn-warning"
                >
                  {t("close-session")}
                </Button>
            ) : (
                <Button type="button" className="cart btn  btn-success" disabled>
                  {t("Free")}
                </Button>
            ),
      },
    ];

    const roomColumns = [
      {
        name: t("Room Name"),
        selector: (row) => row?.name,
        sortable: true,
      },
      {
        name: t("Hotel Name"),
        selector: (row) => row?.hotelName,
        sortable: true,
      },
      {
        name: t("Amount"),
        selector: (row) => row?.amount,
        sortable: true,
        cell: (row) => <span>{row?.amount}</span>,
      },
      {
        name: "Time Elapsed",
        selector: (row) => row?.time,
        sortable: true,
        cell: (row) => <span>{row?.time}</span>,
      },
      {
        name: t("Session Time"),
        selector: (row) => row?.sessionTime,
        sortable: true,
        cell: (row) => row.sessionTime && <span>{row?.sessionTime}</span>,
      },
      {
        name: "Order From",
        selector: (row) => row?.sessionStatus,
        sortable: true,
      },
      {
        width: "200px",
        name: "Session Id",
        selector: (row) => row?.sessions,
        sortable: true,
        cell: (row) => {
          if (row?.sessionsStatus === "started") {
            return (
                <button
                    onClick={() => this.goToOrderSession(row?.sessions)}
                    className="btn  btn-link"
                >
                  <span> {row?.sessions}</span>
                </button>
            );
          } else {
            return null;
          }
        },
      },
      // {
      //   name: t("QR Code"),
      //   selector: (row) => row?.qrCodeData,
      //   sortable: true,
      //   cell: (row) =>
      //     row?.qrCodeData ? (
      //       <Button
      //         outline
      //         className="btn-icon"
      //         color="primary"
      //         onClick={() =>
      //           this.openQrModal(row?.qrCodeData, row?.name, row?.qrCodeData)
      //         }
      //       >
      //         <QRCode value={row?.qrCodeData} size={35} />
      //       </Button>
      //     ) : null,
      // },
      {
        name: t("Action"),
        width: "200px",
        cell: (row) =>
            row?.status === "Occupied" ? (
                <Button
                    onClick={() => this.props.closeLocationSession(row?.sessionId)}
                    type="submit"
                    className="cart btn  btn-warning"
                >
                  {t("close-session")}
                </Button>
            ) : (
                <Button type="button" className="cart btn  btn-success" disabled>
                  {t("Free")}
                </Button>
            ),
      },
    ];

    const tableDataFinal = {
      columns: tableColumns,
      data: tableData,
      print: false,
      export: false,
    };

    const roomDataFinal = {
      columns: roomColumns,
      data: roomData,
      print: false,
      export: false,
    };

    return (
        <>
          <div className="col-12">
            <Nav tabs>
              <NavItem>
                <NavLink
                    className={this.state.activeTab === "1" ? "active" : ""}
                    onClick={() => {
                      this.toggleTab("1");
                    }}
                >
                  Tables
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                    className={this.state.activeTab === "2" ? "active" : ""}
                    onClick={() => {
                      this.toggleTab("2");
                    }}
                >
                  Rooms
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={this.state.activeTab}>
              <TabPane tabId="1">
                <Row>
                  <Col sm="12" lg="12">
                    <DataTableExtensions {...tableDataFinal}>
                      <DataTable
                          noHeader
                          pagination
                          paginationRowsPerPageOptions={[25, 50, 100]}
                          paginationPerPage={50}
                          columns={tableColumns}
                          data={tableData}
                          customStyles={customStyles}
                      />
                    </DataTableExtensions>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="2">
                <Row>
                  <Col sm="12" lg="12">
                    <DataTableExtensions {...roomDataFinal}>
                      <DataTable
                          noHeader
                          pagination
                          paginationRowsPerPageOptions={[25, 50, 100]}
                          paginationPerPage={50}
                          columns={roomColumns}
                          data={roomData}
                          customStyles={customStyles}
                      />
                    </DataTableExtensions>
                  </Col>
                </Row>
              </TabPane>
            </TabContent>
          </div>

          <Modal
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                maxWidth: "500px",
              }}
              isOpen={this.state.qrModalOpen}
              toggle={this.closeQrModal}
          >
            <ModalBody>
              <div className="display-flex full-width flex-space-between">
                <Button.Ripple
                    style={{ width: 20, height: 20 }}
                    onClick={this.closeQrModal}
                    className="m-0 p-0"
                    color="danger"
                >
                  <X size={14} />
                </Button.Ripple>
              </div>
              <div style={{ textAlign: "center", padding: "50px" }}>
                <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                >
                  <Button
                      style={buttonStyle}
                      className="btn-icon"
                      color="primary"
                      onClick={() => this.redirectToURL(this.state.redirectURL)}
                  >
                    <Link size={16} style={{ marginRight: "5px" }} />{" "}
                    {t("qr-url")}
                  </Button>
                  <h4 style={{ marginBottom: "-3px" }}>
                    {this.state.qrImageName}
                  </h4>
                  <div
                      ref={this.qrRef}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "100%",
                      }}
                  >
                    <QRCode value={this.state.qrImageUrl} size={400}  renderAs={"svg"}/>
                  </div>
                  <Button
                      style={buttonStyle}
                      className="btn-icon"
                      color="primary"
                      onClick={() => this.handleDownloadImage()}
                  >
                    {t("download")}
                  </Button>
                </div>
              </div>
            </ModalBody>
          </Modal>
        </>
    );
  }
}

export default withTranslation("global")(ListSessionTablesStatus);
