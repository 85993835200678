import React from "react";
import * as Icon from "react-feather";
import Waiter from "../assets/img/waiterIcon.png";

const managerNavigationConfig = [
  {
    id: "dashboard",
    title: "dashboard",
    type: "item",
    icon: <Icon.Home size={25} />,
    permissions: ["admin", "editor"],
    navLink: "/",
    starred: false,
  },
  {
    id: "pos",
    title: "POS",
    type: "item",
    icon: <Icon.Monitor size={25} />,
    permissions: ["admin", "editor"],
    navLink: "/pos",
    starred: false,
  },
  {
    id: "ongoingSessions",
    title: "ongoing Sessions",
    type: "item",
    icon: <Icon.BookOpen size={25} />,
    permissions: ["admin"],
    navLink: "/tables/session",
    starred: false,
  },
  {
    id: "tables",
    title: "services",
    type: "collapse",
    icon: (
      <img src={Waiter} alt="ServallImage" width={35} className="darkImg" />
    ),
    permissions: ["admin"],
    starred: false,
    children: [
      {
        id: "sessions",
        title: "sessions History",
        type: "item",
        icon: <Icon.Key size={25} />,
        permissions: ["admin", "editor"],
        navLink: "/sessions",
        starred: false,
      },
      {
        id: "posOrders",
        title: "POS Orders History",
        type: "item",
        icon: <Icon.Key size={25} />,
        permissions: ["admin", "editor"],
        navLink: "/sessions/directPOS",
        starred: false,
      },
      {
        id: "directOrders",
        title: "Direct Orders History",
        type: "item",
        icon: <Icon.Key size={25} />,
        permissions: ["admin", "editor"],
        navLink: "/sessions/direct",
        starred: false,
      },

      {
        id: "survey_list",
        title: "session Surveys",
        type: "item",
        icon: <Icon.Activity size={25} />,
        permissions: ["admin", "editor"],
        navLink: "/survey_list",
        starred: false,
      },
      {
        id: "Reservations",
        title: "reservations",
        type: "item",
        icon: <Icon.Bell size={25} />,
        permissions: ["admin", "editor"],
        navLink: "/reservations",
        starred: false,
      },
      // {
      //   id: "tablets_list",
      //   title: "Tablets",
      //   type: "item",
      //   icon: <Icon.Tablet size={20} />,
      //   permissions: ["admin", "editor"],
      //   navLink: "/tablets",
      //   starred: false,
      // },
    ],
  },
  {
    id: "menu-manager",
    title: "menu",
    type: "collapse",
    icon: <Icon.Menu size={25} />,
    permissions: ["admin", "editor"],
    starred: false,
    children: [
      {
        id: "menu_list",
        title: "manage Menu",
        type: "item",
        icon: <Icon.Menu size={25} />,
        permissions: ["admin", "editor"],
        navLink: "/locations/menu",
        starred: false,
      },
      {
        id: "allergic-items",
        title: "manage Allergic Items",
        type: "item",
        icon: <Icon.CloudSnow size={25} />,
        permissions: ["admin", "editor"],
        navLink: "/allergic_items",
        starred: false,
      },
      {
        id: "dish-tags",
        title: "manage Dish Tags",
        type: "item",
        icon: <Icon.Tag size={25} />,
        permissions: ["admin", "editor"],
        navLink: "/dish_tags",
        starred: false,
      },
      {
        id: "modifiers",
        title: "manage Variants",
        type: "item",
        icon: <Icon.Archive size={25} />,
        permissions: ["admin", "editor"],
        navLink: "/locations/modifiers",
        starred: false,
      },
      {
        id: "previews",
        title: "manage Previews",
        type: "item",
        icon: <Icon.Printer size={25} />,
        permissions: ["admin", "editor"],
        navLink: "/previews",
        starred: false,
      },
    ],
  },
  {
    id: "Applications",
    title: "apps",
    type: "collapse",
    icon: <Icon.Aperture size={25} />,
    permissions: ["admin", "editor"],
    starred: false,
    children: [
      {
        id: "eater_app",
        title: "manage Eater App",
        type: "item",
        icon: <Icon.Smartphone size={25} />,
        permissions: ["admin", "editor"],
        navLink: "/eater",
        starred: false,
      },
      {
        id: "pdf",
        title: "manage QR Code",
        type: "item",
        icon: <Icon.Code size={25} />,
        permissions: ["admin", "editor"],
        navLink: "/location/upload/pdf",
        starred: false,
      },
      // {
      //   id: 'qr_app',
      //   title: 'Manage QR-Code',
      //   icon: <Icon.Upload size={16} />,
      //   permissions: ['admin', 'editor'],
      //   navLink: 'location/upload/pdf',
      //   starred: false,
      // },
      {
        id: "website",
        title: "manage Website",
        type: "item",
        icon: <Icon.Monitor size={25} />,
        permissions: ["admin", "editor"],
        navLink: "/website",
        starred: false,
      },
      {
        id: "kitchen_app",
        title: "manage Kitchen App",
        type: "item",
        icon: <Icon.Archive size={25} />,
        permissions: ["admin", "editor"],
        navLink: "/kitchen",
        starred: false,
      },
      {
        id: "waiter_app",
        title: "manage Waiter App",
        type: "item",
        icon: <Icon.Printer size={25} />,
        permissions: ["admin", "editor"],
        navLink: "/waiter",
        starred: false,
      },
    ],
  },

  {
    id: "admin",
    title: "configuration",
    type: "collapse",
    icon: <Icon.Settings size={25} />,
    children: [
      {
        id: "floor",
        title: "manage Floor",
        type: "item",
        icon: <Icon.Grid size={25} />,
        permissions: ["admin", "editor"],
        navLink: "/floors",
        starred: false,
      },
      // {
      //   id: "tables_list",
      //   title: "manage Tables List",
      //   type: "item",
      //   icon: <Icon.List size={25} />,
      //   permissions: ["admin", "editor"],
      //   navLink: "/tables",
      //   starred: false,
      // },
      // {
      //   id: 'branding',
      //   title: 'manage Branding',
      //   type: 'item',
      //   icon: <Icon.Flag size={25} />,
      //   permissions: ['admin'],
      //   navLink: '/branding',
      //   starred: false,
      // },
      {
        id: "banner",
        title: "Manage Branding",
        type: "item",
        icon: <Icon.Image size={25} />,
        permissions: ["admin", "editor"],
        navLink: "/banners",
        starred: false,
      },
      {
        id: "terms_conditions",
        title: "Manage Welcome Text",
        type: "item",
        icon: <Icon.Type size={25} />,
        permissions: ["admin"],
        navLink: "/location/terms_conditions",
        starred: false,
      },
      // {
      //   id: 'banner',
      //   title: 'Manage Branding/Banners',
      //   type: 'item',
      //   icon: <Icon.Image size={25} />,
      //   permissions: ['admin', 'editor'],
      //   navLink: '/banners',
      //   starred: false,
      // },
      {
        id: "checkout-message",
        title: "manage Checkout Message",
        type: "item",
        icon: <Icon.ShoppingBag size={25} />,
        permissions: ["admin", "editor"],
        navLink: "/checkout_message",
        starred: false,
      },
      {
        id: "sendpopup",
        title: "manage Popups",
        type: "item",
        icon: <Icon.MessageSquare size={25} />,
        permissions: ["admin"],
        navLink: "/send/popups",
        starred: false,
      },
      {
        id: "survey",
        title: "manage Survey ",
        type: "item",
        icon: <Icon.Activity size={25} />,
        permissions: ["admin"],
        navLink: "/survey_questions",
        starred: false,
      },
      // {
      //   id: "payment",
      //   title: "manage Payment ",
      //   type: "item",
      //   icon: <Icon.CreditCard size={25} />,
      //   permissions: ["admin"],
      //   navLink: "/payment",
      //   starred: false,
      // },
      {
        id: "license",
        title: "manage Licenses ",
        type: "item",
        icon: <Icon.Award size={25} />,
        permissions: ["admin"],
        navLink: "/license",
        starred: false,
      },
    ],
    starred: false,
  },
  {
    id: "timeslots",
    title: "Time Slots",
    type: "item",
    icon: <Icon.Clock size={25} />,
    navLink: "/location/timeslots",
    starred: false,
  },
  {
    id: "stats",
    title: "overall Stats",
    type: "item",
    icon: <Icon.BarChart2 size={25} />,
    navLink: "/location/stats",
    starred: false,
  },
  {
    id: "analytics",
    title: "reporting",
    type: "collapse",
    icon: <Icon.FileText size={25} />,
    children: [
      {
        id: "overall-stats",
        title: "Sales Report",
        type: "item",
        icon: <Icon.PieChart size={25} />,
        permissions: ["admin", "editor"],
        navLink: "/locations/overall_stats",
      },
      {
        id: "item-stats",
        title: "item Stats",
        type: "item",
        icon: <Icon.BarChart size={25} />,
        permissions: ["admin", "editor"],
        navLink: "/locations/item_stats",
      },
      {
        id: "purchase-history",
        title: "purchase History",
        type: "item",
        icon: <Icon.Slack size={20} />,
        permissions: ["admin", "editor"],
        navLink: "/purchase-history",
      },
    ],
    starred: false,
  },
  {
    id: "support",
    title: "support",
    type: "collapse",
    icon: <Icon.User size={25} />,
    children: [
      {
        id: "faq",
        title: "faq",
        type: "item",
        icon: <Icon.Bookmark size={25} />,
        permissions: ["admin", "editor"],
        navLink: "/faq",
        starred: false,
      },
      {
        id: "wiki",
        title: "wiki",
        type: "item",
        icon: <Icon.Globe size={25} />,
        permissions: ["admin"],
        navLink: "/wiki",
        starred: false,
      },
      {
        id: "training",
        title: "training",
        type: "item",
        icon: <Icon.HelpCircle size={25} />,
        permissions: ["admin"],
        navLink: "/training",
        starred: false,
      },
      {
        id: "contact",
        title: "contact US",
        type: "item",
        icon: <Icon.Phone size={25} />,
        permissions: ["admin"],
        navLink: "/contactus",
        starred: false,
      },
      {
        id: "emergency",
        title: "emergency",
        type: "item",
        icon: <Icon.AlertOctagon size={25} />,
        permissions: ["admin"],
        navLink: "/emergency",
        starred: false,
      },
    ],
    starred: false,
  },

  {
    id: "logout",
    title: "logout",
    type: "item",
    icon: <Icon.LogOut size={25} />,
    permissions: ["admin", "editor"],
    navLink: "/logout",
    starred: false,
  },
];
export default managerNavigationConfig;
