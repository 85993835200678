import React, { Component } from "react";
import { connect } from "react-redux";
import { Card, Table, Button, Badge } from "reactstrap";
import { getLocation } from "../../redux/actions/locations";
import SpinnerComponent from "../@vuexy/spinner/Fallback-spinner";
import { getSessionsList, delSession } from "../../redux/actions/ordersessions";
import LocationService from "../../services/LocationService";
import SelectLocation from "../shared/SelectLocation";
import LocationSelectHeader from "../shared/LocationSelectHeader";
import Pagination from "../shared/Pagination";
import ViewHelper from "../../helpers/ViewHelper";
import { Link } from "react-router-dom";
import "../../assets/css/orders.scss";
import CONSTANTS from "../../constants";
import { Check, Trash2, X } from "react-feather";
import ConfirmationModal from "../shared/forms/ConfirmationModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTable,
  faUsers,
  faFileExport,
  faBox,
} from "@fortawesome/free-solid-svg-icons";
import SessionSearchForm from "../shared/searchForm/SessionSearchForm";
import { ExportToExcel } from "../../helpers/ExportToExcel";
import OrderService from "../../services/OrderService";
import { withTranslation } from "react-i18next";
import i18n from "i18next";
import Checkbox from "../shared/fields/Checkbox";
import CheckBoxesVuexy from "../@vuexy/checkbox/CheckboxesVuexy";
import toast from "react-hot-toast";

class OrderSessions extends Component {
  state = {
    selectLocation: false,
    location: null,
    loading: true,
    isOutlet: false,
    page: 0,
    confirmationDialog: {
      show: false,
      acceptCallback: () => {},
      rejectCallback: () => {},
      title: this.props.t("are-you-sure-to-close-this-session"),
      header: this.props.t("close-session"),
    },
    searchItem: null,
  };
  async componentDidMount() {
    const savedLanguage = localStorage.getItem("lang");
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }

    const location = LocationService.getLocation();
    if (location) {
      await this.refreshData(location.loc_id);
      this.setState({ loading: false });
    } else {
      this.setState({ loading: false, selectLocation: true });
    }
  }

  async refreshData(loc_id, initialize = true, fetchSesionsOrder = true) {
    // this.setState({ loading: initialize, refreshing: true });
    this.setState({
      loading: initialize,
      refreshing: true,
      selectedSessions: new Set(),
    });
    if (!this.state.location || loc_id !== this.state.location.loc_id) {
      await this.props.getLocation(loc_id);
    }

    if (fetchSesionsOrder) {
      await this.props.getSessionsList({ loc_id });
    }

    this.setState({
      loading: false,
      location: JSON.parse(JSON.stringify(this.props.location)),
      isOutlet: this.props.location.is_outlet,
    });
  }

  toggleLocation() {
    this.setState({ selectLocation: !this.state.selectLocation });
  }

  async onSelect(location) {
    this.setState({ loading: true });
    await LocationService.setLocation(location);
    await this.refreshData(location.loc_id);
    this.setState({ selectLocation: false, loading: false });
  }

  closeLocationSession = (session) => {
    console.log(session.session_id, "session");
    this.showConfirmationDialog(
      async () => {
        await this.props.delSession([session.session_id]);
        const location = LocationService.getLocation();
        if (location) {
          await this.refreshData(location.loc_id);
          this.setState({ loading: false });
        } else {
          this.setState({ loading: false, selectLocation: true });
        }
      },

      null,
      this.props.t("are-you-sure-you-want-to-delete-this-session"),
      this.props.t("delete-session")
    );
  };
  // handleBulkDelete = async () => {
  //   const { selectedSessions } = this.state;
  //   if (selectedSessions.size > 0) {
  //     this.showConfirmationDialog(
  //       async () => {
  //         const sessionsToDelete = Array.from(selectedSessions);
  //         await this.props.delSession(sessionsToDelete);
  //         const location = LocationService.getLocation();
  //         if (location) {
  //           await this.refreshData(location.loc_id);
  //           this.setState({ loading: false });
  //         } else {
  //           this.setState({ loading: false, selectLocation: true });
  //         }
  //       },
  //       null,
  //       this.props.t("are-you-sure-you-want-to-delete-this-session"),
  //       this.props.t("delete-session")
  //     );
  //   } else {
  //     console.log("No sessions selected for deletion");
  //   }
  // };

  handleBulkDelete = async () => {
    const { selectedSessions } = this.state;

    if (selectedSessions.size > 0) {
      try {
        await this.showConfirmationDialog(
          async () => {
            const sessionsToDelete = Array.from(selectedSessions);
            await this.props.delSession(sessionsToDelete);
            const location = LocationService.getLocation();
            if (location) {
              await this.refreshData(location.loc_id);
              this.setState({ loading: false });
            } else {
              this.setState({ loading: false, selectLocation: true });
            }
          },
          null,
          this.props.t("are-you-sure-you-want-to-delete-this-session"),
          this.props.t("delete-session")
        );
      } catch (error) {
        console.error("Error deleting sessions:", error);
        toast.error("No sessions selected for deletion");
      }
    } else {
      console.log("No sessions selected for deletion");
    }
  };

  handleCheckboxChange = (session_id) => {
    const selectedSessions = new Set(this.state.selectedSessions);
    if (selectedSessions.has(session_id)) {
      selectedSessions.delete(session_id);
    } else {
      selectedSessions.add(session_id);
    }
    this.setState({ selectedSessions });
  };
  renderOrdersSession(sessionList) {
    console.log("SESSIONLIST", sessionList);
    if (sessionList.length === 0)
      return (
        <div className="p-3 border-top-1">
          {this.props.t("no-session-order-found")}
        </div>
      );
    return (
      <Table
        responsive
        className="dashboard-table table-hover-animation mb-0 mt-1 text-nowrap"
      >
        <thead>
          <tr>
            <th style={{ width: "14%" }}></th>
            <th style={{ width: "14%" }}>Sessions ID</th>

            <th style={{ width: "14%" }}>{this.props.t("table")}</th>
            <th style={{ width: "14%" }}>Room</th>
            <th style={{ width: "14%" }}>{this.props.t("order-amount")}</th>
            <th style={{ width: "14%" }}>
              {this.props.t("session-start-date")}
            </th>
            <th style={{ width: "14%" }}>{this.props.t("start-time-0")}</th>
            <th style={{ width: "14%" }}>{this.props.t("session-end-date")}</th>
            <th style={{ width: "14%" }}>{this.props.t("end-time-0")}</th>
            <th style={{ width: "14%" }}>{this.props.t("device")}</th>
            <th style={{ width: "14%" }}>Payment Method</th>
            <th style={{ width: "14%" }}>{this.props.t("session-status")}</th>
            <th style={{ width: "14%" }}>Send to POS</th>
          </tr>

          {/* <tr>
            <th style={{ width: "14%" }}>{this.props.t("sessions")}</th>

            <th style={{ width: "14%" }}>{this.props.t("table")}</th>
            <th style={{ width: "14%" }}>Room</th>
            <th style={{ width: "14%" }}>{this.props.t("order-amount")}</th>
            <th style={{ width: "14%" }}>{this.props.t("session-date-0")}</th>
            <th style={{ width: "14%" }}>{this.props.t("start-time-0")}</th>
            <th style={{ width: "14%" }}>{this.props.t("end-time-0")}</th>
            <th style={{ width: "14%" }}>{this.props.t("device")}</th>
            <th style={{ width: "14%" }}>{this.props.t("session-status")}</th>
            <th style={{ width: "14%" }}></th>
            <th style={{ width: "14%" }}>Sessions ID</th>
            <th style={{ width: "14%" }}>{this.props.t("table")}</th>
            <th style={{ width: "14%" }}>{this.props.t("order-amount")}</th>
            <th style={{ width: "14%" }}>
              {this.props.t("session-start-date")}
            </th>
            <th style={{ width: "14%" }}>{this.props.t("start-time-0")}</th>
            <th style={{ width: "14%" }}>{this.props.t("session-end-date")}</th>
            <th style={{ width: "14%" }}>{this.props.t("end-time-0")}</th>
            <th style={{ width: "14%" }}>{this.props.t("device")}</th>
            <th style={{ width: "14%" }}>Payment Method</th>
            <th style={{ width: "14%" }}>{this.props.t("session-status")}</th>
          </tr> */}
        </thead>
        {
          <tbody>
            {sessionList.map((session) => this.renderOrderSession(session))}
          </tbody>
        }
      </Table>
    );
  }

  renderOrderSession(session) {
    const isSelected = this.state.selectedSessions?.has(session.session_id);
    console.log(isSelected, "isisisisis");
    return (
      <tr key={session.session_id}>
        <td>
          <CheckBoxesVuexy
            checked={isSelected}
            onChange={() => this.handleCheckboxChange(session.session_id)}
          />
        </td>
        <td id="item_parent">
          <FontAwesomeIcon color="purple" icon={faUsers} />
          <Link
            to={`/sessions/${session.session_id}`}
            // className="btn"

            className="text-dark "
            color="primary"
            onClick={this.goToOrderSession.bind(this, session.session_id)}
          >
            #{session.session_id}
          </Link>
        </td>
        <td>
          {session.table_name ? (
            <Badge
              style={{ width: "130px" }}
              color="primary"
              pill
              className="text-capitalize "
            >
              <FontAwesomeIcon icon={faTable} className="mr-1" />
              {session?.table_name}
            </Badge>
          ) : (
            <span></span>
          )}
        </td>

        <td>
          {session.room_name ? (
            <Badge color="primary" pill className="text-capitalize ">
              <FontAwesomeIcon icon={faTable} className="mr-1" />
              {session?.room_name}
            </Badge>
          ) : (
            <span></span>
          )}
        </td>
        {session?.session_order_amount ? (
          <td className="p-1">
            {ViewHelper.toCurrency(
              session?.session_order_amount,
              this.props.location?.Currency?.currency_code
            )}
          </td>
        ) : (
          <td>{this.props.t("no-order-amount")}</td>
        )}
        <td className="p-1">
          <i
            className="feather icon-calendar"
            style={{ marginRight: "5px" }}
          ></i>
          {session.session_date}
        </td>
        <td className="p-1">
          <i className="feather icon-clock" style={{ marginRight: "5px" }}></i>
          {session.start_time}
        </td>
        <td className="p-1">
          <i
            className="feather icon-calendar"
            style={{ marginRight: "5px" }}
          ></i>
          {session.session_end_date}
        </td>
        {session.end_time ? (
          <td>
            <i
              className="feather icon-clock"
              style={{ marginRight: "5px" }}
            ></i>
            {session.end_time}
          </td>
        ) : (
          <td>
            <Badge color="danger" pill className="text-uppercase">
              {this.props.t("in-progress")}{" "}
            </Badge>
          </td>
        )}
        <td>{session.order_from}</td>

        <td className="text-uppercase text-center">{session.payment_method}</td>
        <td className="p-1">
          {session.session_status === "completed" ? (
            <Badge
              color="success"
              pill
              className="text-uppercase"
              style={{ width: "100px", padding: "6px" }}
            >
              {this.props.t("completed")}{" "}
            </Badge>
          ) : (
            <Badge
              color="warning"
              pill
              className="text-uppercase"
              style={{ width: "100px" }}
            >
              {this.props.t("started")}{" "}
            </Badge>
          )}
        </td>
        <td className="p-1">
          {session.send_to_pos === true ? (
            <Badge
              color="success"
              pill
              className="text-uppercase"
              style={{ width: "100px", padding: "6px" }}
            >
              True
            </Badge>
          ) : (
            <Badge
              color="danger"
              pill
              className="text-uppercase"
              style={{ width: "100px" }}
            >
              False
            </Badge>
          )}
        </td>
        <td>
          <Trash2
            style={{ cursor: "pointer" }}
            className="text-danger"
            onClick={() => this.closeLocationSession(session)}
          />
        </td>
      </tr>
    );
  }

  goToOrderSession(sessionid) {
    this.props.history.push(`${CONSTANTS.BASE_URL}sessions/${sessionid}`);
  }

  async getOrdersSession(page, searchItem = null) {
    if (searchItem == null) {
      searchItem = this.state.searchItem;
    }
    let params = { page, loc_id: this.state.location.loc_id };
    await this.props.getSessionsList(params, searchItem);
  }

  onPageChange(page) {
    this.setState({ page: page.selected }, () => {
      this.getOrdersSession(page.selected);
    });
  }

  showConfirmationDialog(accept, reject, title, header) {
    const defaultState = {
      show: false,
      acceptCallback: () => {},
      rejectCallback: () => {},
      title: "",
      header: "",
    };

    const confirmationDialog = {
      show: true,
      acceptCallback: async () => {
        if (accept) accept();
        this.setState({ confirmationDialog: defaultState });
      },
      rejectCallback: () => {
        this.setState({ confirmationDialog: defaultState });
        if (reject) reject();
      },
      title: title,
      header: header,
    };
    this.setState({ confirmationDialog });
  }

  async exportAllSessionHistory() {
    let params = {
      page: this.state.page,
      loc_id: this.state.location.loc_id,
      loc_information: this.state.location,
    };
    console.log("PARAMS", params);
    let session = await OrderService.getExportSessionList(
      params,
      this.state.searchItem
    );
    let exportSessionData = [];
    let exportSessionHeader = [
      this.props.t("table"),
      this.props.t("room"),
      this.props.t("session-date-0"),
      this.props.t("start-time"),
      this.props.t("end-time"),
      this.props.t("status"),
      this.props.t("items"),
      this.props.t("options"),
      this.props.t("sub-total"),
      this.props.t("total-tax"),
      this.props.t("total-amount"),
    ];
    if (session.length > 0) {
      session.map((data) => {
        let session_items = "";
        let session_items_sep = "";
        let subTotalAmount = 0;
        let tax = 0;
        let totalAmount = 0;
        let optionItems = "";
        let optionItemsSep = "";
        let currency_code =
          this.state.location?.Currency?.currency_symbol || "";
        if (data?.Orders.length > 0) {
          data.Orders.map((order) => {
            if (order?.OrderMenus.length > 0) {
              order.OrderMenus.map((menu) => {
                session_items += session_items_sep;
                session_items +=
                  menu.name + ` (${currency_code}${menu.subtotal})`;
                session_items_sep = " , ";
                if (menu?.OrderOptions.length > 0) {
                  menu.OrderOptions.map((option) => {
                    optionItems += optionItemsSep;
                    optionItems +=
                      option.order_option_name +
                      ` (${currency_code}${option.order_option_price})`;
                    optionItemsSep = " , ";
                  });
                }
              });
            }

            if (order?.OrderTotals.length > 0) {
              order.OrderTotals.map((order_total) => {
                if (order_total.code === "tax") {
                  tax += order_total.value;
                }
                if (order_total.code === "order_total") {
                  totalAmount += order_total.value;
                }
                if (order_total.code === "cart_total") {
                  subTotalAmount += order_total.value;
                }
              });
            }
          });
        }
        exportSessionData.push({
          table_name: data?.Table?.table_name,
          room_name: data?.Room?.room_name,
          session_date: data?.session_date,
          start_time: data?.start_time,
          end_time: data?.end_time,
          status: data?.session_status,
          items: session_items,
          options: optionItems,
          sub_total: currency_code + subTotalAmount,
          tax: currency_code + tax,
          total: currency_code + totalAmount,
        });
        console.log("EXPORTSESSIONDATA", exportSessionData);
      });

      ExportToExcel({
        data: exportSessionData,
        header: exportSessionHeader,
        fileName: "sessionHistory",
      });
    }
  }

  async searchSessionData(searchItem, resetform) {
    if (resetform) {
      this.setState({ loading: true });
    }
    searchItem = new URLSearchParams(searchItem).toString();
    this.setState({ searchItem: searchItem });
    await this.getOrdersSession(0, searchItem);

    this.setState({ loading: false });
  }
  handleSelectAll() {
    const { selectedSessions } = this.state;
    const allSessionIds = this.props.sessionslist.map(
      (session) => session.session_id
    );

    if (selectedSessions.size === allSessionIds.length) {
      this.setState({ selectedSessions: new Set() });
    } else {
      this.setState({ selectedSessions: new Set(allSessionIds) });
    }
  }
  render() {
    const { t } = this.props;
    const {
      props: { location, sessionslist, totalCount },
    } = this;
    const {
      state: { selectLocation, loading },
    } = this;
    const {
      state: { confirmationDialog },
    } = this;
    // if (loading) return <SpinnerComponent />;
    const locationName = location ? location.name : "";
    if (selectLocation)
      return (
        <SelectLocation
          onBack={this.toggleLocation.bind(this)}
          onSelect={this.onSelect.bind(this)}
        />
      );

    return (
      <div className="location">
        <div className="display-flex flex-row justify-content-between mb-1">
          <div className="d-flex">
            <LocationSelectHeader
              text={locationName}
              onIconClick={this.toggleLocation.bind(this)}
            />
            <Button
              className="btn-sm ml-1"
              onClick={() => this.exportAllSessionHistory()}
            >
              <FontAwesomeIcon color="white" icon={faFileExport} />{" "}
              {t("export")}
            </Button>
          </div>
        </div>
        <SessionSearchForm
          searchSessionData={(searchItem, resetform) =>
            this.searchSessionData(searchItem, resetform)
          }
        />

        <div className="display-flex flex-row justify-content-between mt-2">
          <div className="d-flex">
            <button
              type="button"
              class="btn btn-primary"
              onClick={() => this.handleSelectAll()}
            >
              {this?.state?.selectedSessions?.size ===
              this?.props?.sessionslist?.length
                ? "DeSelect All"
                : "Select All"}
            </button>
            <button
              type="button"
              class="btn btn-danger ml-1"
              onClick={() => this.handleBulkDelete()}
            >
              Delete
            </button>
          </div>
        </div>
        {/* {this.renderOrdersSession(sessionslist)} */}
        {loading && <SpinnerComponent />}

        {!loading && this.renderOrdersSession(sessionslist)}
        {sessionslist.length > 0 && (
          <Pagination
            onPageChange={this.onPageChange.bind(this)}
            page={this.state.page}
            count={totalCount}
          />
        )}
        <ConfirmationModal
          header={confirmationDialog.header}
          title={confirmationDialog.title}
          isOpen={confirmationDialog.show}
          reject={confirmationDialog.rejectCallback.bind(this)}
          rejectTitle={t("cancel")}
          accept={confirmationDialog.acceptCallback.bind(this)}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { sessionslist } = state.orders;
  const { location } = state.locations;
  return {
    sessionslist: sessionslist?.data?.data ? sessionslist?.data?.data : [],
    totalCount: sessionslist?.data?.count ? sessionslist?.data?.count : 0,
    loading: sessionslist.loading || location.loading,
    location: location.data,
  };
}

export default withTranslation("global")(
  connect(mapStateToProps, {
    getSessionsList,
    getLocation,
    delSession,
  })(OrderSessions)
);
