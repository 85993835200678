import { combineReducers } from 'redux'
import { dummyItem,allModifiers,allDishTags , allAllergicItems } from "./MenuReducer";


const menuReducer = combineReducers({
  dummyItem,
  allModifiers,
  allDishTags,
  allAllergicItems
});

export default menuReducer;
