import React, { useState, useEffect } from "react";
import { Modal, Button, Navbar, Form, InputGroup } from "react-bootstrap";
import LocationService from "../../services/LocationService";
import MenuService from "../../services/MenuService";
import { createDirectOrder } from "../../services/DirectOrderService";
import SpinnerComponent from "../@vuexy/spinner/Fallback-spinner";
import MenuOptionsModal from "./MenuOptionsModal";
import { ShoppingCart } from "react-feather";
import dummyFoodImage from "../../assets/img/dummy-food.png";
import logo from "../../assets/img/Servall W logo.png";
import {
  clearCart,
  getAllAmounts,
  getCartTotal,
  removeItem,
} from "../../redux/actions/cart";
import {
  Alert,
  Divider,
  IconButton,
  List,
  ListItem,
  Snackbar,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import {
  AddCircleOutline,
  DeleteForeverOutlined,
  LocationOff,
  RemoveCircleOutline,
} from "@mui/icons-material";
import numeral from "numeral";
import { useDispatch, useSelector } from "react-redux";
import CONSTANTS from "../../constants";
import { FormGroup, Input, Label } from "reactstrap";

const PointofSale = () => {
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart.cartMenu);
  console.log("CART", cart);
  const [isLoading, setIsLoading] = useState(true);
  const [locationData, setLocationData] = useState();
  const [menu, setMenu] = useState([]);
  const [customer, setCustomer] = useState({
    name: "",
    email: "",
    number: "",
    address: "",
    longitude: "",
    latitude: "",
  });
  const [showOptionsModal, setShowOptionsModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [activeCategory, setActiveCategory] = useState(null);
  const [orderType, setOrderType] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [discount, setDiscount] = useState("");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [errors, setErrors] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [customerType, setCustomerType] = useState("");
  const [discountAmount, setDiscountAmount] = useState("");
  const [tabValue, setTabValue] = useState(0);

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
    setActiveCategory(
      newValue === 0 ? null : menu.menu_data[newValue - 1].Category.name
    );
  };

  const handleOrderType = (type) => {
    setOrderType(type);
  };
  const handleClearOrder = () => {
    handleClearCart();
    setDiscountAmount();
    setDiscount("");
  };

  const handleCustomerTypeChange = (event) => {
    const selectedType = event.target.value;
    console.log("EVENT", selectedType);
    setCustomerType(selectedType);
  };

  console.log("CUSTOMERTYPEEEEEE", customerType);
  const handleCategoryClick = (category) => {
    setActiveCategory(category);
  };
  const filteredMenuItems = activeCategory
    ? menu.menu_data.filter(
        (category) => category.Category.name === activeCategory
      )
    : menu.menu_data;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCustomer({ ...customer, [name]: value });
  };

  useEffect(() => {
    const fetchData = async () => {
      const location = LocationService.getLocation();
      if (location) {
        setLocationData(location);
        try {
          const menuData = await MenuService.getLocationMenu(location.slug);
          setMenu(menuData);
          setIsLoading(false);
        } catch (error) {
          console.error("Error fetching menu data:", error);
        }
      }
    };
    fetchData();
  }, []);

  console.log("LOCATIONDATA", locationData);
  const handleCustomerChange = (e) => {
    const { value } = e.target;
    setDiscount({ value });
  };

  console.log("DISCOUNT", discount);
  const saveCustomerData = () => {
    const errors = {};
    if (!customer.name) errors.name = "Name is required";
    if (!customer.email) errors.email = "Email is required";
    if (!customer.number) errors.number = "Phone number is required";
    if (!customer.address) errors.address = "Address is required";
    if (!customer.longitude) errors.longitude = "Longitude is required";
    if (!customer.latitude) errors.latitude = "Latitude is required";
    setErrors(errors);

    if (Object.keys(errors).length === 0) {
      console.log("Customer data saved:", customer);
      setShowModal(false);
    }
  };

  const calculateDiscount = () => {
    const percentageDiscount = parseFloat(discount.value) || 0; // Get user input and convert to float
    const totalAmount = cart.totalamount;
    const discountAmount = (percentageDiscount / 100) * totalAmount;
    setDiscountAmount(discountAmount);
    console.log("Discount amount:", discountAmount);
    return discountAmount;
  };

  const applyDiscount = () => {
    const discount = calculateDiscount(); // Calculate the discount

    const updatedTotalAmount = cart.totalamount - discount;
    const updatedSubTotal = cart.subtotal - discount;
    const updatedTotalTax = cart.totaltax;

    const payload = {
      subTotal: updatedSubTotal,
      totalTax: updatedTotalTax,
      totalAmount: updatedTotalAmount,
    };

    dispatch(getAllAmounts(payload));
  };

  // const applyDiscount = () => {
  //   const discount = parseInt(customer.discount);
  //   if (!isNaN(discount)) {
  //     setCustomer((prevCustomer) => ({
  //       ...prevCustomer,
  //       discount: discount,
  //     }));
  //   } else {
  //     setCustomer((prevCustomer) => ({
  //       ...prevCustomer,
  //       discount: 0,
  //     }));
  //   }
  // };

  const handleIncrement = (itemIndex) => {
    console.log("ITEMINDEX", itemIndex);
    console.log("CART", cart);
    const updatedCart = cart.cart.map((item, index) => {
      console.log("ITEM AND INDEX", item, index);
      if (index === itemIndex) {
        const updatedItem = {
          ...item,
          itemCount: item.itemCount + 1,
          order_menu_tax:
            (item.order_menu_tax / item.itemCount) * (item.itemCount + 1),
          MenuOptions: item.MenuOptions.map((menuOption) => ({
            ...menuOption,
            Option_Values: menuOption.Option_Values.map((optionValue) => {
              let initialOptionCount = optionValue.initialOptionCount;

              if (initialOptionCount === undefined) {
                initialOptionCount = optionValue.optionCount;
              }

              console.log("INTIALOPTION", initialOptionCount);

              const updatedOptionValue = {
                ...optionValue,
                optionCount: optionValue.optionCount + initialOptionCount,
                initialOptionCount,
              };

              console.log("OPTIONVALUSS", updatedOptionValue);

              return updatedOptionValue;
            }),
          })),
        };

        console.log("UPDATEDITEM", updatedItem);
        return updatedItem;
      }
      return item;
    });

    console.log("UPDATEDCART", updatedCart);

    dispatch(removeItem({ itemIndex, items: updatedCart }));
  };

  const handleDecrement = (itemIndex) => {
    const updatedCart = cart.cart.map((item, index) => {
      if (index === itemIndex) {
        const updatedItem = {
          ...item,
          itemCount: item.itemCount > 1 ? item.itemCount - 1 : 1,
          order_menu_tax:
            (item.order_menu_tax / item.itemCount) * (item.itemCount - 1),
          MenuOptions: item.MenuOptions.map((menuOption) => ({
            ...menuOption,
            Option_Values: menuOption.Option_Values.map((optionValue) => {
              let initialOptionCount = optionValue.initialOptionCount;

              if (initialOptionCount === undefined) {
                initialOptionCount = optionValue.optionCount;
              }

              const newOptionCount =
                optionValue.optionCount > initialOptionCount
                  ? optionValue.optionCount - initialOptionCount
                  : initialOptionCount;

              return {
                ...optionValue,
                optionCount: newOptionCount,
                initialOptionCount,
              };
            }),
          })),
        };
        return updatedItem;
      }
      return item;
    });

    // removeItem({ itemIndex, cart: updatedCart });
    dispatch(removeItem({ itemIndex, items: updatedCart }));
  };
  const handleRemoveItem = (itemIndex) => {
    let remainItems = [];
    cart.cart.map((item, index) => {
      if (index !== itemIndex) {
        remainItems.push(item);
      }
    });

    dispatch(removeItem({ itemIndex, items: remainItems }));
  };
  const handleClearCart = () => {
    dispatch(clearCart());
  };

  const handlePlaceOrder = async () => {
    const currentTime = new Date();
    const currentHours = currentTime.getHours();
    const currentMinutes = currentTime.getMinutes();
    const currentSeconds = currentTime.getSeconds();
    const formattedCurrentTime = `${currentHours}:${currentMinutes}:${currentSeconds}`;
    const year = currentTime.getUTCFullYear();
    const month = ("0" + (currentTime.getUTCMonth() + 1)).slice(-2);
    const day = ("0" + currentTime.getUTCDate()).slice(-2);
    const formattedDate = `${year}-${month}-${day}`;
    console.log(" customerType,", customerType);

    const customerTypeObject = { [customerType]: true };
    const datatoSend = {
      customerType: { ...customerTypeObject },
      totalQuantity: 0,
      totalamount: 0,
      subtotal: 0,
      totaltax: 0,
      loc_id: null,
      user_id: null,
      table_no: null,
      table_name: null,
      order_type: orderType,
      order_owner: [
        {
          ...customer,
        },
      ],
      total_items: cart.cart.length,
      order_time: formattedCurrentTime,
      order_date: formattedDate,
      order_type_id: null,
      order_variant: "small",
      discount_id: 0,
      total_discount: discountAmount,
      totalPrice: cart.totalamount,
      start_time: formattedCurrentTime,
      location_id: locationData.loc_id,
      comment: "",
      end_time: null,
      qrcode: "",
      qrcodedata: "",
      pos_order: true,
      promocode_id: null,
      payment_status_id: null,
      order_tax: cart.totaltax,
      menu_items: cart.cart,
    };
    try {
      const response = await createDirectOrder(datatoSend);
      if (response) {
        setSnackbarMessage("Order placed successfully!");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
        handleClearCart();
        setCustomer([]);
        setOrderType("");
        setCustomerType("");
      } else {
        setSnackbarMessage("Failed to place order. Please try again.");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error("Error placing order:", error);
      setSnackbarMessage("Error placing order. Please try again.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const showMenuOptionsModal = (item) => {
    setSelectedItem(item);
    setShowOptionsModal(true);
  };
  const formatPrice = (price) => {
    if (price >= 1000) {
      const formattedValue = numeral(price).format("0.0a");
      return formattedValue;
    } else {
      return numeral(price).format("0.00");
    }
  };

  const calculateTotalPrice = (menuPrice, taxPercent, itemCount) => {
    console.log("tax percent", taxPercent);
    console.log("menuPrice", menuPrice);
    const itemPrice = menuPrice || 0;
    const tax = taxPercent || 0;

    const totalPrice =
      tax > 0
        ? (itemPrice + (itemPrice * tax) / 100) * itemCount
        : itemPrice * itemCount;

    return formatPrice(totalPrice);
  };
  const calculateVariantPrice = (optionPrice, taxPercent, itemCount) => {
    console.log("OPTOPPRICE", optionPrice);
    console.log("TAXPRICE", taxPercent);
    console.log("ITEMCOUNT", itemCount);
    const itemPrice = optionPrice || 0;
    const tax = taxPercent || 0;
    const variantPrice =
      tax > 0
        ? itemPrice * itemCount * (tax / 100) + itemPrice * itemCount
        : itemPrice * itemCount;

    return variantPrice;
  };

  return (
    <div className="container-fluid">
      <Navbar
        bg="primary"
        expand="lg"
        className="mb-3"
        style={{ backgroundColor: "#343a40" }}
      >
        <Navbar.Brand style={{ color: "white" }}>POS System</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          {/* Your search form */}
        </Navbar.Collapse>
        <Navbar.Collapse className="justify-content-end">
          <img
            src={logo}
            alt="Logo"
            style={{
              maxHeight: "70px",
              background: "black",
              // paddingRight: "10px",
              border: "2px solid white",
            }}
          />
        </Navbar.Collapse>
      </Navbar>
      <div className="row">
        <div className="col-md-8">
          {isLoading ? (
            <SpinnerComponent />
          ) : (
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12 mb-2">
                  <Tabs
                    value={tabValue}
                    onChange={handleChangeTab}
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="menu tabs"
                  >
                    <Tab label="All Items" />
                    {menu?.menu_data?.map((category, index) => (
                      <Tab key={index} label={category.Category.name} />
                    ))}
                  </Tabs>
                </div>
              </div>

              <div style={{ overflowX: "auto" }}>
                {filteredMenuItems.map((category, index) => (
                  <div key={index} id={`category-${index}`}>
                    <div className="container">
                      <div className="mb-2">
                        <h4
                          className="mb-1"
                          style={{ fontWeight: "bold", color: "green" }}
                        >
                          {category.Category.name}
                        </h4>
                        <hr style={{ borderTop: "2px solid green" }} />
                      </div>

                      <div className="row">
                        {category.Menu.map((item2, index2) => (
                          <div key={index2} className="col-md-3">
                            <div
                              className="card"
                              style={{
                                height: "300px",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-between",
                              }}
                            >
                              <div className="card-body text-center">
                                <img
                                  src={
                                    item2.menu_photo
                                      ? CONSTANTS.RESTAURANT_URL +
                                        item2.menu_photo
                                      : dummyFoodImage
                                  }
                                  alt={item2.name}
                                  className="card-img-top mx-auto d-block"
                                  style={{
                                    height: "100px",
                                    objectFit: "contain",
                                  }}
                                />
                                <h6 className="mt-1">{item2.menu_name}</h6>
                                <p className="card-text">
                                  {locationData?.Currency?.currency_symbol}
                                  {item2.menu_price}
                                </p>
                              </div>
                              <div className="card-footer text-center">
                                <button
                                  onClick={() => showMenuOptionsModal(item2)}
                                  className="btn btn-primary"
                                >
                                  <i className="bi bi-plus"></i> +
                                </button>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>

        <Modal show={showModal} onHide={() => setShowModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Customer Information</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId="name">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  value={customer.name}
                  onChange={handleInputChange}
                  isInvalid={!!errors.name}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.name}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="email">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  value={customer.email}
                  onChange={handleInputChange}
                  isInvalid={!!errors.email}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.email}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="number">
                <Form.Label>Phone Number</Form.Label>
                <Form.Control
                  type="text"
                  name="number"
                  value={customer.number}
                  onChange={handleInputChange}
                  isInvalid={!!errors.number}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.number}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="address">
                <Form.Label>Address</Form.Label>
                <Form.Control
                  type="text"
                  name="address"
                  value={customer.address}
                  onChange={handleInputChange}
                  isInvalid={!!errors.address}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.address}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="longitude">
                <Form.Label>Longitude</Form.Label>
                <Form.Control
                  type="text"
                  name="longitude"
                  value={customer.longitude}
                  onChange={handleInputChange}
                  isInvalid={!!errors.longitude}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.longitude}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="latitude">
                <Form.Label>Latitude</Form.Label>
                <Form.Control
                  type="text"
                  name="latitude"
                  value={customer.latitude}
                  onChange={handleInputChange}
                  isInvalid={!!errors.latitude}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.latitude}
                </Form.Control.Feedback>
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowModal(false)}>
              Close
            </Button>
            <Button variant="primary" onClick={saveCustomerData}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>

        <div className="col-md-4 d-flex flex-column">
          {/* <Button
            size="small"
            className="mb-3"
            onClick={() => setShowModal(true)}
          >
            Enter Customer Details
          </Button> */}
          <FormGroup>
            <Label for="customerType" className="font-weight-bold">
              Customer Type
            </Label>
            <Input
              type="select"
              id="customerType"
              value={customerType}
              onChange={handleCustomerTypeChange}
            >
              <option value="" disabled>
                Please choose customer type
              </option>
              <option value="walk_in">Walk-in Customer</option>
            </Input>
          </FormGroup>
          <div className="d-flex mx-auto justify-content-center mb-3">
            <Button
              variant={orderType === "delivery" ? "primary" : "outline-primary"}
              onClick={() => handleOrderType("delivery")}
              className="mr-1"
            >
              Delivery
            </Button>
            <Button
              variant={orderType === "takeaway" ? "primary" : "outline-primary"}
              onClick={() => handleOrderType("takeaway")}
            >
              Takeaway
            </Button>
          </div>

          <div className="card  border-primary shadow mb-1">
            <div className="card-body">
              <Form>
                <Form.Group controlId="discount">
                  <Form.Label className="font-weight-bold">
                    Discount %
                  </Form.Label>
                  <InputGroup>
                    <Form.Control
                      type="number"
                      placeholder="Enter discount"
                      value={discount.discount}
                      onChange={handleCustomerChange}
                      name="discount"
                    />
                    <Button
                      variant="outline-primary"
                      className="font-weight-bold"
                      onClick={applyDiscount}
                      disabled={discountAmount > 0}
                    >
                      Apply
                    </Button>
                    <InputGroup.Append></InputGroup.Append>
                  </InputGroup>
                </Form.Group>
              </Form>
            </div>
          </div>

          <div className="card h-100 border-primary shadow">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-center mb-3">
                <h2 className="mb-0">Cart</h2>
                <ShoppingCart className="bi bi-cart4" size={24} />
              </div>
              {cart?.cart.length === 0 ? (
                <Typography
                  style={{
                    padding: "16px",
                    textAlign: "center",
                    color: "#888",
                  }}
                >
                  {"no-items-in-cart"}{" "}
                </Typography>
              ) : (
                <List style={{ maxHeight: "300px", overflowY: "auto" }}>
                  {cart.cart.map(
                    (item, index) => (
                      console.log("ITEM REDUX CART"),
                      (
                        <React.Fragment key={item.id}>
                          <ListItem alignItems="flex-start">
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                width: "100%",
                              }}
                            >
                              <IconButton
                                className="delete-btn"
                                edge="start"
                                aria-label="remove"
                                onClick={() => handleRemoveItem(index)}
                                style={{
                                  color: "#175450",
                                  margin: "1px -2px",
                                  fontWeight: "bold",
                                }}
                              >
                                <DeleteForeverOutlined
                                  style={{ color: "red", fontSize: "17px" }}
                                />
                              </IconButton>

                              <div style={{ flex: 1 }}>
                                {!item.MenuOptions.some(
                                  (option) => option.base_price === 1
                                ) ? (
                                  <Typography
                                    variant="body2"
                                    component="div"
                                    color="textSecondary"
                                    style={{
                                      fontSize: "16px",
                                      fontWeight: "bold",
                                      color: "#175450",
                                    }}
                                  >
                                    {item.itemCount +
                                      "x " +
                                      item.menu_name +
                                      " = " +
                                      locationData?.Currency?.currency_symbol +
                                      "" +
                                      calculateTotalPrice(
                                        item.menu_price,
                                        item.menu_tax_percentage,
                                        item.itemCount
                                      )}
                                  </Typography>
                                ) : (
                                  <Typography
                                    variant="body2"
                                    component="div"
                                    color="textSecondary"
                                    style={{
                                      fontSize: "16px",
                                      fontWeight: "bold",
                                      color: "#175450",
                                    }}
                                  >
                                    {item.menu_name}
                                  </Typography>
                                )}
                                {item.MenuOptions.length > 0 && (
                                  <div>
                                    {item.MenuOptions.map(
                                      (menu_option, index) => (
                                        <React.Fragment
                                          key={menu_option.option_id}
                                        >
                                          {index !== 0 && <Divider />}
                                          <Typography
                                            variant="body2"
                                            component="div"
                                            fontWeight="bold"
                                            color="textSecondary"
                                          >
                                            {menu_option.option_name}
                                          </Typography>
                                          {menu_option.Option_Values.map(
                                            (menu_option_value) => (
                                              <Typography
                                                key={
                                                  menu_option_value.option_id
                                                }
                                                variant="body2"
                                                component="div"
                                                color="textSecondary"
                                              >
                                                {`${
                                                  menu_option_value.optionCount
                                                }x ${
                                                  menu_option_value.value
                                                } = ${
                                                  menu_option_value.price === 0
                                                    ? "free"
                                                    : locationData?.Currency
                                                        ?.currency_symbol +
                                                      calculateVariantPrice(
                                                        menu_option_value.price,
                                                        menu_option_value.order_item_tax_percentage,
                                                        menu_option_value.optionCount
                                                      ).toFixed(2)
                                                }`}
                                              </Typography>
                                            )
                                          )}
                                        </React.Fragment>
                                      )
                                    )}
                                  </div>
                                )}
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                }}
                              >
                                <IconButton
                                  className="plus-btn"
                                  style={{
                                    margin: "3px 2px",
                                    color: "#ffffff",
                                    backgroundColor: "#175450",
                                    fontWeight: 20,
                                    fontSize: "7px",
                                  }}
                                  edge="end"
                                  aria-label="increment"
                                  onClick={() => handleIncrement(index)}
                                >
                                  <AddCircleOutline
                                    style={{
                                      color: "#ffffff",
                                      fontWeight: 50,
                                      fontSize: "17px",
                                    }}
                                  />
                                </IconButton>

                                <IconButton
                                  className="plus-btn"
                                  style={{
                                    margin: "3px 3px",
                                    color: "#ffffff",
                                    backgroundColor: "#175450",
                                    fontWeight: 20,
                                    fontSize: "7px",
                                  }}
                                  edge="end"
                                  aria-label="increment"
                                  onClick={() => handleDecrement(index)}
                                >
                                  <RemoveCircleOutline
                                    style={{
                                      color: "#ffffff",
                                      fontWeight: 50,
                                      fontSize: "17px",
                                    }}
                                  />
                                </IconButton>
                              </div>
                            </div>
                          </ListItem>
                          <div
                            style={{
                              borderTop: "1px solid #888",
                            }}
                          ></div>
                        </React.Fragment>
                      )
                    )
                  )}
                </List>
              )}

              {cart.cart.length > 0 && (
                <>
                  <>
                    <div>
                      <div
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      ></div>
                      <div
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <div style={{ flex: 1, textAlign: "right" }}>
                          <Typography
                            variant="subtitle1"
                            style={{ fontWeight: "bold", marginRight: "8px" }}
                          >
                            VAT
                          </Typography>
                        </div>
                        <div>
                          <Typography
                            variant="subtitle1"
                            style={{ fontWeight: "bold" }}
                          >
                            {locationData?.Currency?.currency_symbol +
                              "" +
                              formatPrice(cart.totaltax)}
                          </Typography>
                        </div>
                      </div>
                      <div
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <div style={{ flex: 1, textAlign: "right" }}>
                          <Typography
                            variant="subtitle1"
                            style={{ fontWeight: "bold", marginRight: "8px" }}
                          >
                            Total Excl VAT:
                          </Typography>
                        </div>
                        <div>
                          <Typography
                            variant="subtitle1"
                            style={{ fontWeight: "bold" }}
                          >
                            {locationData?.Currency?.currency_symbol +
                              "" +
                              formatPrice(cart.subtotal)}
                          </Typography>
                        </div>
                      </div>
                      <div
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <div style={{ flex: 1, textAlign: "right" }}>
                          <Typography
                            variant="subtitle1"
                            style={{ fontWeight: "bold", marginRight: "8px" }}
                          >
                            Total:
                          </Typography>
                        </div>

                        <div>
                          <Typography
                            variant="subtitle1"
                            style={{ fontWeight: "bold" }}
                          >
                            {locationData?.Currency?.currency_symbol +
                              "" +
                              formatPrice(cart.totalamount)}
                          </Typography>
                        </div>
                      </div>
                      {discountAmount > 0 && (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <div style={{ flex: 1, textAlign: "right" }}>
                            <Typography
                              variant="subtitle1"
                              style={{ fontWeight: "bold" }}
                            >
                              Discount Amount:{" "}
                              {locationData?.Currency?.currency_symbol +
                                "" +
                                discountAmount}
                            </Typography>
                          </div>
                        </div>
                      )}
                    </div>
                  </>
                  <div className="d-flex justify-content-between">
                    <button
                      className="btn btn-danger mx-2"
                      onClick={handleClearOrder}
                    >
                      Clear Cart
                    </button>
                    <button
                      className="btn btn-primary"
                      onClick={handlePlaceOrder}
                    >
                      Place Order
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {selectedItem && (
        <MenuOptionsModal
          open={showOptionsModal}
          onClose={() => setShowOptionsModal(false)}
          itemName={selectedItem}
          restaurantData={locationData}
        />
      )}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity={snackbarSeverity}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default PointofSale;
